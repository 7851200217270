<title>Auto-net - {{ 'tradeInCar.title' | translate }}</title>

<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
      <div class="row py-md-1">
        <div class="container pt-2">

            <div class="row align-items-center">
                <div class="col-md-8">
                    <h1>{{ 'trade-in-car.title' | translate }}</h1>
                    <p>{{ 'trade-in-car.paragraph1' | translate }}</p>
                    <p [innerHTML]="'trade-in-car.paragraph2' | translate"></p>
                    <p [innerHTML]="'trade-in-car.paragraph3' | translate"></p>
                </div>                
                <div class="col-md-2 text-center">
                    <img src="../../../assets/images/inruilen-graag.png" class="img-fluid" alt="Inruilen Ja Graag">
                </div>
            </div>
        </div>

        <app-advertisement-overview [advertisements]="advertisements"></app-advertisement-overview>
        </div>
    </div>
</div>