<title>Auto-net - {{ 'header.contact' | translate }}</title>

<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
        <div class="row py-md-1">
            <div class="col-md-6 main-content mt-2">
                <!-- Contact Details -->
                <h1>{{ 'header.contact' | translate }}</h1>
                <h3>{{ 'contact.visitAddress' | translate }}</h3>
                <p>Vlijtseweg 202<br>7317 AN Apeldoorn (Noord)</p>

                <h3>{{ 'contact.phone' | translate }}</h3>
                <p>{{ 'contact.general' | translate }}: <a href="tel:0888833250">088 - 88 33 250</a><br>{{
                    'contact.afterHours' | translate }}: <a href="tel:0651055825">06 - 510 55 825</a> (van 08:00
                    {{'contact.till' | translate}} 22:00)</p>

                <h3>{{ 'contact.email' | translate }}</h3>
                <p>E-mail: <a href="mailto:verkoop&#64;auto-net.nl">verkoop&#64;auto-net.nl</a></p>

                <h3>{{ 'contact.accountNumber' | translate }}</h3>
                <p>NL03 RABO 0152 822 240<br>{{'contact.inTheNameOf' | translate}} Auto-net.nl BV</p>
            </div>
            <div class="col-lg-6">
                <!-- Map and Images -->
                <h2>{{ 'contact.locationOnMap' | translate }}</h2>
                <!-- Embed Google Map iframe -->
                <iframe
                    src="https://maps.google.nl/maps?f=q&source=s_q&hl=nl&q=auto-net&sll=52.232268,5.973473&sspn=0.031225,0.077162&ie=UTF8&t=m&st=109146043351405611748&rq=1&ev=zi&split=1&radius=1.96&hq=auto-net&hnear=&ll=52.232268,5.973473&spn=0.031225,0.077162&output=embed"
                    width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <div class="row mt-3">
                    <!-- Images of the location -->
                    <div class="col-sm-6">
                        <img src="../../../assets/images/pand1.jpg" class="img-fluid" alt="Auto-net Location 1">
                    </div>
                    <div class="col-sm-6">
                        <img src="../../../assets/images/pand2.jpg" class="img-fluid" alt="Auto-net Location 2">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>