import { Component, Input } from '@angular/core';
import { Data as Advertisement } from 'src/app/models/advertisement.model';

@Component({
  selector: 'app-advertisement-print',
  templateUrl: './advertisement-print.component.html',
  styleUrl: './advertisement-print.component.scss'
})

export class AdvertisementPrintComponent {
  @Input({ required: true }) advertisement!: Advertisement;
}
