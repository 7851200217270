// Angular Modules
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Component, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import * as Sentry from "@sentry/angular";
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

// NgBootstrap Modules
import { NgbModule, NgbAccordionModule, NgbDropdownModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Other Third-party Modules
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ToastrModule } from 'ngx-toastr';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { GalleryModule } from '@ks89/angular-modal-gallery';

// Translation Modules
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MainComponent } from './pages/main/main.component';
import { AdvertisementDetailComponent } from './pages/advertisement-detail/advertisement-detail.component';
import { AdvertisementOverviewComponent } from './pages/main/advertisement-overview/advertisement-overview.component';
import { AdvertisementListComponent } from './pages/advertisement-list/advertisement-list.component';
import { ContactComponent } from './pages/contact/contact.component';
import { OverAutoNetComponent } from './pages/over-auto-net/over-auto-net.component';
import { FavorietenComponent } from './pages/favorieten/favorieten.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { AutoVerkopenComponent } from './pages/auto-verkopen/auto-verkopen.component';
import { AutoInruilenComponent } from './pages/auto-inruilen/auto-inruilen.component';
import { NotFoundComponent } from './pages/404/404.component';
import { GarantiebepalingComponent } from './pages/garantiebepaling/garantiebepaling.component';

// Services
import { ApiService } from './services/api.service';
import { InterceptorService } from './services/interceptor.service';
import { FavoritesService } from './services/favorites.service';
import { ScrollService } from './services/scroll.service';
import { MetaService } from './services/meta.service';

// Pipes
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { RegistrationPipe } from './pipes/registration.pipe';
import { AppRoutingModule } from './app.routes';
import { YesNoPipe } from './pipes/yesno.pipe';
import { CurrencyFormatPipe } from './pipes/currency.pipe';
import { AdvertisementPrintComponent } from './pages/advertisement-print/advertisement-print.component';
import { PhoneNumberFormatterDirective } from './directives/phoneNumberFormatter';
import { PrivacyverklaringComponent } from './pages/privacyverklaring/privacyverklaring.component';
import { NieuwsbriefModalComponent } from './pages/nieuwsbrief-modal/nieuwsbrief-modal.component';
import { ModalStateService } from './services/modal-state.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Router } from '@angular/router';

// Translation Loader Factory Function
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Cookie Consent Configuration
const cookieConfig: NgcCookieConsentConfig = {
  position: "bottom-left",
  palette: {
    popup: {
      background: "#ffffff",
      text: "#000000",
      link: "#2279d2"
    },
    button: {
      background: '#2279d2'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  content: {
    message: 'Deze website gebruikt cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.',
    allow: 'Cookies accepteren',
    deny: 'Weigeren',
    link: 'Privacyverklaring',
    href: 'privacyverklaring',
    policy: 'Cookiebeleid',
    target: '_blank'
  }
};

@NgModule({
  declarations: [
    // Components
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MainComponent,
    AdvertisementDetailComponent,
    AdvertisementOverviewComponent,
    AdvertisementListComponent,
    ContactComponent,
    FavorietenComponent,
    OverAutoNetComponent,
    ReviewsComponent,
    AutoVerkopenComponent,
    AdvertisementPrintComponent,
    PrivacyverklaringComponent,
    GarantiebepalingComponent,
    NieuwsbriefModalComponent,
    AutoInruilenComponent,
    NotFoundComponent,
    // Pipes
    CapitalizePipe,
    RegistrationPipe,
    YesNoPipe,
    CurrencyFormatPipe,
    // Directtives
    PhoneNumberFormatterDirective
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    // Angular Modules
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // NgBootstrap Modules
    NgbModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    // Other Third-party Modules
    SlickCarouselModule,
    SimplebarAngularModule,
    ToastrModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    GalleryModule,
    NgxSliderModule,
    // Translation Module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })], providers: [
      // Services
      ApiService,
      FavoritesService,
      ModalStateService,
      ScrollService,
      NgbActiveModal,
      MetaService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptorService,
        multi: true,
      },
      { provide: LOCALE_ID, useValue: 'nl-NL' },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: true,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
