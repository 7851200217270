<title>{{ 'notFound.seo.title' | translate }}</title>

<!-- Page container-->
<div class="container pt-5">
  <div class="container rounded bg-white mt-5 mb-md-4">
    <div class="row py-md-1">
      <div class="col-md-6 main-content mt-2">
        <h1>{{ 'notFound.content.title' | translate }}</h1>
        <p>{{ 'notFound.content.description' | translate }}</p>
        <a routerLink="/">{{ 'notFound.content.homeLink' | translate }}</a>
      </div>
    </div>
  </div>
</div>
