<title>Auto-net - {{ 'about.title' | translate }}</title>
<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
      <div class="row py-md-1">
            <div class="col-md-9 main-content mt-2">
                <!-- Main content -->
                <h1>{{ 'about.title' | translate }}</h1>
                <h2>{{ 'about.newStartForUsedCars' | translate }}</h2>
                <p>
                    {{ 'about.paragraph1' | translate }}<br><br>
                    {{ 'about.paragraph2' | translate }}
                </p>

                <h3>{{ 'about.certainty' | translate }}</h3>
                <p>
                    {{ 'about.certaintyDetails' | translate }}
                </p>

                <h3>{{ 'about.tradeInCar' | translate }}</h3>
                <p>
                    {{ 'about.tradeInCarDetails' | translate }}
                </p>

                <h3>{{ 'about.wideSelection' | translate }}</h3>
                <p>
                    {{ 'about.wideSelectionDetails' | translate }}
                </p>

                <h3>{{ 'about.documentation' | translate }}</h3>
                <p>
                    {{ 'about.documentationDetails' | translate }}
                </p>

                <h3>{{ 'about.onlinePurchase' | translate }}</h3>
                <p>
                    {{ 'about.onlinePurchaseDetails' | translate }}
                </p>

                <h3>{{ 'about.noDeliveryCosts' | translate }}</h3>
                <p>
                    {{ 'about.noDeliveryCostsDetails' | translate }}
                </p>

                <h3>{{ 'about.anyInspectionAllowed' | translate }}</h3>
                <p>
                    {{ 'about.anyInspectionAllowedDetails' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
