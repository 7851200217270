<title>Auto-net - Privacyverklaring</title>

<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
        <div class="row py-md-1">
            <div class="col-md-9 main-content mt-2">
                <div class="twelve columns">
                    <h1>Privacyverklaring Auto-net.nl B.V.</h1>

                    <h2>Introductie</h2>
                    <p>ANN hecht veel waarde aan privacy en neemt haar verantwoordelijkheid om uw privacy te beschermen
                        en draagt daarom zorg voor het vertrouwelijk behandelen van de door uw verschafte
                        (persoons)gegevens. ANN verwerkt persoonsgegevens op basis van de verplichtingen uit de
                        toepasselijke wet- en regelgeving omtrent persoonsgegevensbescherming, waaronder de Algemene
                        verordening gegevensbescherming.
                        ANN heeft deze privacyverklaring opgesteld om u te informeren over hoe wij persoonsgegevens
                        verwerken. In deze privacyverklaring beschrijven wij welke persoonsgegevens door ons worden
                        verzameld, waarom wij deze gegevens verzamelen, hoe deze gegevens door ons worden verwerkt én
                        waar deze gegevens door ons worden opgeslagen. Deze privacyverklaring is van toepassing op alle
                        producten/diensten van ANN.</p>

                    <h2>Identiteit</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td>Dienstverlener:</td>
                                <td>Auto-net.nl B.V.</td>
                            </tr>
                            <tr>
                                <td>Bezoekadres:</td>
                                <td>Vlijtseweg 202</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>7317 AN Apeldoorn (Noord)</td>
                            </tr>
                            <tr>
                                <td>Postadres:</td>
                                <td>Postbus 4128</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>7320 AC Apeldoorn</td>
                            </tr>
                            <tr>
                                <td>Telefoonnummer: </td>
                                <td>078-6323334</td>
                            </tr>
                            <tr>
                                <td>E-mailadres:</td>
                                <td><a href="mailto: verkoop&#64;auto-net.nl">verkoop&#64;auto-net.nl</a></td>
                            </tr>
                            <tr>
                                <td>Kvk:</td>
                                <td>51261979</td>
                            </tr>
                        </tbody>
                    </table>

                    <br>

                    <h2>Waarom verwerken wij uw gegevens?</h2>
                    <ul>
                        <li style="margin-bottom:0px">Voor het uitvoeren van onze dienstverlening/verkoop van producten;
                        </li>
                        <li style="margin-bottom:0px">Voor algemene bedrijfsvoering;</li>
                        <li style="margin-bottom:0px">Voor communicatiedoeleinden;</li>
                        <li style="margin-bottom:0px">Voor het versturen van onze nieuwsbrief;</li>
                        <li style="margin-bottom:0px">Voor het verwerken van sollicitaties;</li>
                        <li style="margin-bottom:0px">Voor marketingdoeleinden;</li>
                        <li style="margin-bottom:0px">Voor het verbeteren van onze website;</li>
                    </ul>

                    <h2>Welke gegevens verwerken wij?</h2>
                    <ul>
                        <li style="margin-bottom:0px">NAW-gegevens;</li>
                        <li style="margin-bottom:0px">Bedrijfsgegevens van klanten;</li>
                        <li style="margin-bottom:0px">Bankgegevens van klanten;</li>
                        <li style="margin-bottom:0px">E-mailadres van klanten;</li>
                        <li style="margin-bottom:0px">Telefoonnummers van klanten.</li>
                    </ul>

                    <h2>Op welke gronden verwerken wij uw gegevens?</h2>
                    <ul>
                        <li style="margin-bottom:0px">Ter uitvoering van een overeenkomst;</li>
                        <li style="margin-bottom:0px">Op basis van een gerechtvaardigd belang;</li>
                        <li style="margin-bottom:0px">Op grond van een wettelijke verplichting;</li>
                        <li style="margin-bottom:0px">Op grond van toestemming.</li>
                    </ul>

                    <h2>Hoe lang bewaren wij uw gegevens?</h2>
                    Wij verwerken uw gegevens voor een vooraf uitdrukkelijk bepaald doel. Indien een doel is
                    gerealiseerd, verwijderen wij de gegevens die wij voor dat doel hebben verzameld.
                    <p>In het kader van fiscale verplichtingen zijn wij wettelijk verplicht uw gegevens voor een bepaald
                        termijn te bewaren. De termijn voor fiscale basisadministratie bedraagt zeven (7) jaar.</p>

                    <h2>Met wie delen wij uw gegevens?</h2>
                    Hieronder staan de categorieën van partijen beschreven, waarvan ANN diensten en/of producten afneemt
                    voor haar bedrijfsvoering. Ten opzichte van de verwerkingen van onderstaande partijen is ANN
                    verwerkingsverantwoordelijke, en met deze partijen zijn schriftelijke afspraken gemaakt over het
                    verwerken en beschermen van de gegevens:
                    <ul>
                        <li style="margin-bottom:0px">Met een derde partij die verantwoordelijk is voor de hosting van
                            de website van ANN;</li>
                        <li style="margin-bottom:0px">Met een derde partij die verantwoordelijk is voor een
                            onlinedienst, waarmee ANN haar nieuwsbrieven verstuurt;</li>
                        <li style="margin-bottom:0px">Met een derde partij die verantwoordelijk is voor het hosten van
                            de zakelijke e-mail business account.</li>
                    </ul>
                    <br>
                    <h2>Welke rechten heeft u?</h2>
                    <h3>Intrekken van uw toestemming</h3>
                    <p>Indien de verwerking is gebaseerd op de rechtsgrond 'toestemmin', dan kunt u deze toestemming te
                        allen tijde intrekken. Het intrekken van uw toestemming heeft geen invloed op de wettigheid van
                        eerder uitgevoerde processen die zijn uitgevoerd op basis van de toestemming vóór uw
                        terugtrekking.</p>
                    <h3>Recht om te verzoeken, te corrigeren en te verwijderen</h3>
                    <p>Wanneer ANN uw persoonsgegevens verwerkt, heeft u het recht op een redelijke toegang. ANN zal u
                        uw persoonlijke gegevens verstrekken nádat u hiertoe een verzoek heeft gedaan.<br>
                        Indien uw persoonsgegevens onjuist zijn of de gegevensverwerking is irrelevant of tijdens ons
                        verwerkingsproces is de wet overtreden, dan kunt u ANN verzoeken om uw persoonsgegevens te
                        corrigeren of te verwijderen.</p>
                    <h3>Beperking van de verwerking</h3>
                    <p>Als u de juistheid van uw persoonsgegevens betwist, dan heeft u het recht om die verwerking te
                        beperken voor een periode dat ANN de juistheid van uw persoonsgegevens kan controleren. Indien
                        uw persoonlijke gegevens onjuist zijn, dan corrigeert ANN dit zo spoedig mogelijk.</p>
                    <h3>Kennisgeving van uw rechten</h3>
                    <p>ANN informeert u te allen tijde over uw verzoek, nádat uw persoonlijke gegevens zijn
                        gerectificeerd, ná het verwijderen van uw persoonsgegevens en wanneer de verwerking van uw
                        persoonsgegevens beperkt is.</p>
                    <h3>Gegevensoverdraagbaarheid</h3>
                    <p>U heeft het recht om de persoonsgegevens te ontvangen die u aan ANN heeft verstrekt. ANN zorgt
                        dat u uw persoonlijke gegevens ontvangt in een gestructureerde, veelgebruikte en
                        machine-leesbaar formaat. Verder heeft u het recht om uw persoonsgegevens te verzenden naar een
                        derde zonder enig hinder van ANN.</p>
                    <h3>Hoe maakt u gebruik van uw rechten?</h3>
                    <p>Stuur een e-mail naar <a href="mailto:info&#64;auto-net.nl">info&#64;auto-net.nl</a> met het
                        verzoek van welk recht u gebruik wilt maken en om welke verwerking van gegevens het precies
                        gaat. Zorg dat u uw persoonlijke gegevens vermeldt, zodat ANN u kan identificeren.<br>
                        Ná uw verzoek om uw rechten te gebruiken, zal ANN zo snel mogelijk - maar uiterlijk binnen één
                        (1) maand - na ontvangst van het verzoek reageren. Indien noodzakelijk kan dit termijn met twee
                        (2) maanden verlengd worden, rekening houdend met het aantal verzoeken en de complexiteit
                        daarvan. Over enige vertragingen of verlenging zal ANN u binnen één (1) maand ná ontvangst van
                        het verzoek informeren, met inbegrip van de redenen voor de vertraging.</p>

                    <h2>Vragen en klachten?</h2>
                    <p>Indien u een vraag en/of een klacht heeft kunt u een e-mail sturen naar <a
                            href="mailto: info&#64;auto-net.nl">info&#64;auto-net.nl</a>.<br>
                        Wij streven ernaar om vragen en/of klachten zo goed mogelijk af te handelen.</p>

                    <p>Indien u niet tevreden bent over het afhandelen van uw klacht, dan kunt u te allen tijde een
                        klacht indienen bij de Autoriteit Persoonsgegevens via deze link:<br>
                        <a href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/privacyrechten/privacyklacht-indienen?qa=klacht%20indienen&amp;scrollto=1"
                            target="_blank">https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/privacyrechten/privacyklacht-indienen?qa=klacht%20indienen&amp;scrollto=1</a>.
                    </p>

                    <h2>Wijzigen privacyverklaring</h2>
                    <p>ANN heeft te allen tijde het recht om de inhoud van deze privacyverklaring te wijzigen.
                        Wijzigingen worden gepubliceerd op <a href="https://www.auto-net.nl"
                            target="_blank">www.auto-net.nl</a>.</p>

                    <a href="garantiebepaling">Bekijk garantiebepaling</a>
                </div>
            </div>
        </div>
    </div>
</div>