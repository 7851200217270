import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'registration' })
export class RegistrationPipe implements PipeTransform {

  private sidecodes: RegExp[] = [
    /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/, // 1 XX-99-99
    /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/, // 2 99-99-XX
    /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/, // 3 99-XX-99
    /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/, // 4 XX-99-XX
    /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/, // 5 XX-XX-99
    /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/, // 6 99-XX-XX
    /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/, // 7 99-XXX-9
    /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/, // 8 9-XXX-99
    /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/, // 9 XX-999-X
    /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/, // 10 X-999-XX
    /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/, // 11 XXX-99-X
    /^[a-zA-Z]{1}[\d]{2}[a-zA-Z]{3}$/, // 12 X-99-XXX
    /^[\d]{1}[a-zA-Z]{2}[\d]{3}$/, // 13 9-XX-999
    /^[\d]{3}[a-zA-Z]{2}[\d]{1}$/ // 14 999-XX-9
  ];

  transform(value: string): string {
    const licenseplate = this.parseLicenseplate(value);

    if (licenseplate !== undefined && licenseplate.length === 6) {
      const sidecode = this.getSidecode(licenseplate);
      if (sidecode) {
        if (sidecode <= 6) {
          return `${licenseplate.substr(0, 2)}-${licenseplate.substr(2, 2)}-${licenseplate.substr(4, 2)}`;
        }
        if (sidecode === 7 || sidecode === 9) {
          return `${licenseplate.substr(0, 2)}-${licenseplate.substr(2, 3)}-${licenseplate.substr(5, 1)}`;
        }
        if (sidecode === 8 || sidecode === 10) {
          return `${licenseplate.substr(0, 1)}-${licenseplate.substr(1, 3)}-${licenseplate.substr(4, 2)}`;
        }
        if (sidecode === 11 || sidecode === 14) {
          return `${licenseplate.substr(0, 3)}-${licenseplate.substr(3, 2)}-${licenseplate.substr(5, 1)}`;
        }
        if (sidecode === 12 || sidecode === 13) {
          return `${licenseplate.substr(0, 1)}-${licenseplate.substr(1, 2)}-${licenseplate.substr(3, 3)}`;
        }
      }
    }
    return value;
  }

  private parseLicenseplate(value: string): string {
    return value.replace(/-/g, '').toUpperCase();
  }

  private getSidecode(licenseplate: string): number | undefined {
    for (let index = 0; index < this.sidecodes.length; index++) {
      if (licenseplate.match(this.sidecodes[index])) {
        return index + 1;
      }
    }
    return undefined;
  }
}
