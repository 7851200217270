<title>Auto-net - Een nieuwe start voor gebruikt</title>

<!-- Page container-->
<div class="container mt-5 mb-md-4 ">
    <div class="row py-md-1">
        <section class="bg-hero pt-4">
            <div class="container pt-2">
                <div class="row pt-lg-4 pt-xl-2">
                    <div class="mt-4 pt-3 pt-md-4 pt-lg-5 bg-dark bg-opacity-60 d-md-none">
                        <h1 class="display-4 pb-2 mb-4 me-md-n5 text-light">{{ 'home.viewOurInventory' | translate }}
                        </h1>
                    </div>
                    <div class="col-lg-6 col-md-5 pt-3 pt-md-4 pt-lg-5 d-none d-md-block">
                        <h1 class="display-4 pb-2 mb-4 me-md-n5 text-white">{{ 'home.viewOurInventory' | translate }}
                        </h1>
                    </div>
                </div>
            </div>

            <div class="container mt-4 mt-sm-3 mt-lg-n3 pb-5 mb-md-5">
                <!-- Form group -->
                <form [formGroup]="searchForm" class="col-lg-4 col-sm-12 p-4 bg-white shadow rounded">
                    <p class="fs-lg text-black">{{ 'home.weAreOpen' | translate }}</p>
                    <div class="mb-3" *ngIf="searchOptions">
                        <select formControlName="make" class="form-select" (ngModelChange)="makeSelected()">
                            <option [value]="null">{{ 'home.selectMake' | translate }}</option>
                            <option [value]="make.id" *ngFor="let make of searchOptions.makes">{{ make.label }}</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <select formControlName="model" class="form-select">
                            <option [value]="null">{{ 'home.selectModel' | translate }}</option>
                            <option [value]="model.id" *ngFor="let model of filteredModels">{{ model.label }}</option>
                            <option *ngIf="filteredModels.length === 0">{{ 'home.enterKeyword' | translate }}</option>
                        </select>
                    </div>

                    <div class="d-flex justify-content-between">
                        <button (click)="generateSearchUrl()" [disabled]="!searchForm.valid"
                            class="btn btn-primary flex-fill me-1" type="submit">
                            {{ 'home.search' | translate }}
                        </button>
                        <button [routerLink]="['/occasions']" class="btn btn-secondary flex-fill ms-1" type="button">
                            {{ 'home.viewAll' | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </section>

        <div class="col">
            <div class="row bg-primary text-white text-center py-3">
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <b>{{ 'home.autoNetAssurances' | translate }}</b>
                </div>
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <i class="fi fi-check bg-success"></i> {{ 'home.newStartForUsed' | translate }}
                </div>
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <i class="fi fi-check bg-success"></i> {{ 'home.certainty' | translate }}
                </div>
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <i class="fi fi-check bg-success"></i> {{ 'home.tradeInCar' | translate }}
                </div>
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <i class="fi fi-check bg-success"></i> {{ 'home.wideSelection' | translate }}
                </div>
                <div class="col-12 col-md-auto mb-2 mb-md-0">
                    <i class="fi fi-check bg-success"></i> {{ 'home.documentation' | translate }}
                </div>
                <div class="col-12 col-md-auto ms-md-auto">
                    <a [routerLink]="['/over-auto-net']" class="text-white">{{ 'home.readMore' | translate }}</a>
                </div>
            </div>
        </div>

        <div class="container rounded-top mt-2 mb-0 bg-white" *ngIf="counters">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Nav pills: Justified -->
                    <div class="row">
                        <div class="col">
                            <ul class="nav nav-pills mb-2 mt-2 text-center text-md-left d-flex flex-wrap">
                                <li class="nav-item col-md col-sm-12">
                                    <a href="#" class="nav-link" [class.active]="selectedContent === 'carOfTheWeek'"
                                        (click)="showContent('carOfTheWeek')">{{ 'home.carOfTheWeek' | translate }}</a>
                                </li>
                                <li class="nav-item col-md col-sm-12">
                                    <a href="#" class="nav-link" [class.active]="selectedContent === 'new'"
                                        (click)="showContent('new')">{{ 'home.newWithin' | translate }}
                                        ({{counters.newArrivals}})</a>
                                </li>
                                <li class="nav-item col-md col-sm-12">
                                    <a href="#" class="nav-link" [class.active]="selectedContent === 'sold'"
                                        (click)="showContent('sold')">{{ 'home.recentlySold' | translate }}
                                        ({{counters.sold}})</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container rounded-bottom mt-0 mb-2 bg-white">
            <div *ngIf="selectedContent !== 'carOfTheWeek'">
                <app-advertisement-overview [advertisements]="advertisements"></app-advertisement-overview>
            </div>

            <div *ngIf="selectedContent === 'carOfTheWeek'">
                <app-advertisement-overview [carOfTheWeek]="true"
                    [advertisements]="advertisements"></app-advertisement-overview>
            </div>
        </div>

        <div class="container rounded mt-2 mb-2 bg-white" *ngIf="reviews">
            <div class="container mt-3">
                <h3 class="mb-3">{{ 'home.reviews' | translate }}</h3>
                <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let review of reviews">
                        <!-- Review -->
                        <div class="d-flex justify-content-between mb-3">
                            <div class="d-flex align-items-center pe-2">
                                <div class="ps-2">
                                    <h6 class="fs-base mb-0">{{review.author}}</h6>
                                    <div class="star-rating">
                                        <ng-container *ngFor="let star of getStars(review.rating)">
                                            <i class="star-rating-icon" [ngClass]="{'fi-star-filled active': star, 'fi-star': !star}"></i>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <span class="text-muted fs-sm">{{review.publicationDate.date | date}}</span>
                        </div>
                        <p><b>{{review.experienceShort}}</b></p>
                        <p>{{review.experience}}</p><br>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    
        <div class="container rounded mt-2 mb-2 bg-white" *ngIf="searchOptions">
            <div class="container mt-3">
                <h3 class="mb-3">{{ 'home.overviewOfOccasions' | translate }}</h3>
                <div class="row row-cols-1 row-cols-md-6 g-4">
                    <div class="col" *ngFor="let make of searchOptions.makes">
                        <div class="h-100">
                            <h5 class="text-primary">
                                <a class="text-decoration-none" [routerLink]="['occasions/merk', make.id]"
                                    [attr.href]="'/occasions/merk/' + make.id">{{ make.label }}</a>
                            </h5>
                            <p class="test-grey" *ngIf="filterModels(make.id).length === 0">Niet beschikbaar</p>
                            <p *ngFor="let model of filterModels(make.id)">
                                <a class="text-black text-decoration-none"
                                    [routerLink]="['occasions/merk', make.id, 'model', model.id]">{{ model.label }}</a>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>