import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Generate the Basic Authentication header
    const authData = `${environment.application}:${environment.password}`;
    const authHeaderValue = `Basic ${btoa(authData)}`;

    // Clone the request to add the new header
    const clonedRequest = request.clone({
      headers: request.headers.append('Authorization', authHeaderValue)
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
