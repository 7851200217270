import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    this.setMetaTags();
  }

  private setMetaTags() {
    this.metaService.updateMetaTags({
      titleKey: 'contact.seo.title',
      descriptionKey: 'contact.seo.description',
      keywordsKey: 'contact.seo.keywords',
      authorKey: 'contact.seo.author',
      ogTitleKey: 'contact.seo.ogTitle',
      ogDescriptionKey: 'contact.seo.ogDescription',
      ogImageKey: 'contact.seo.ogImage',
      ogUrlKey: 'contact.seo.ogUrl'
    });
  }
}
