import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'carInventory',
    link: '/occasions/auto',
  },
  {
    id: 2,
    label: 'tradeInCar',
    link: '/auto-inruilen',
  },
  {
    id: 3,
    label: 'sellCar',
    link: '/auto-verkopen',
  },
  {
    id: 4,
    label: 'reviews',
    link: '/reviews',
  },
  {
    id: 5,
    label: 'aboutAutoNet',
    link: '/over-auto-net',
  },
  {
    id: 6,
    label: 'contact',
    link: '/contact',
  }
];

