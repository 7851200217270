import { Component } from '@angular/core';
import { Data as Advertisements } from 'src/app/models/advertisements.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-auto-inruilen',
  templateUrl: './auto-inruilen.component.html',
  styleUrl: './auto-inruilen.component.scss'
})

export class AutoInruilenComponent {

  public advertisements: Advertisements[] = [];
  
  constructor(
    private apiService: ApiService) {
      this.apiService.getAdvertisements(50, 1, {"dateDelivered":"DESC"}, ['unsold']).subscribe((data) => {
        this.advertisements = data.data;
      });
    }
}
