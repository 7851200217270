import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberFormatter]'
})
export class PhoneNumberFormatterDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove all non-numeric characters

    // Check if the number is at the correct length
    if (this.isCorrectLength(value)) {
      input.value = this.formatPhoneNumber(value);
    }
  }

  private isCorrectLength(value: string): boolean {
    // Adjust this length based on your phone number rules
    return value.length === 10 || value.length === 9; // 10 for mobile, 9 for landline
  }

  private formatPhoneNumber(value: string): string {
    // Format the phone number based on patterns
    if (value.startsWith('+31')) {
      // Already in international format, no need to change
      return value;
    } else if (value.startsWith('31')) {
      // Already in international format without '+', add '+'
      return '+' + value;
    } else if (value.startsWith('06')) {
      // Mobile number with leading 06
      return '+316' + value.substring(2);
    } else if (value.startsWith('6')) {
      // Mobile number without leading 0
      return '+316' + value.substring(1);
    } else if (value.startsWith('0')) {
      // Landline with leading 0
      return '+31' + value.substring(1);
    } else if (/^[1-9]\d*$/.test(value)) {
      // Landline without leading 0
      return '+31' + value;
    } else {
      // If the number doesn't match any pattern, keep it unchanged
      return '+31' + value;
    }
  }
}
