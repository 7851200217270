<title>Auto-net - {{ 'tradeInCar.title' | translate }}</title>

<main class="page-wrapper">
    <!-- Header-->
    <app-header id="top"></app-header>

    <!-- Page container-->
    <div class="container mt-4 mb-md-4 py-5">
        <div class="row">
            <!-- Page content-->

            <div class="col-lg-8 mt-4">
                <!-- Title-->
                <div class="mb-4 d-lg-none" *ngIf="advertisement">
                    <!-- Card container -->
                    <div class="card">
                        <div class="card-body">
                            <!-- Card title -->
                            <h5 class="card-title">{{ 'tradeInCar.interestVehicle' | translate }}</h5>
                            <!-- Vehicle make and model -->
                            <h6 class="card-subtitle mb-2 text-muted">
                                {{advertisement.vehicle.make}} {{advertisement.vehicle.model}}
                            </h6>
                            <!-- Vehicle image -->
                            <img class="card-img-top img-fluid mt-3" [src]="advertisement.images[0]" alt="Car">
                            <!-- Price -->
                            <p class="card-text">
                                {{advertisement.consumerPrice.amount | currencyFormat}}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Basic info-->
                <form [formGroup]="requestForm" (ngSubmit)="submitRequestForm()">
                    <section class="card  card-body border-0 shadow-sm p-4 mb-4" id="basic-info" #basicinfo
                        *ngIf="currentStep == 'general'">
                        <h1 *ngIf="!advertisement" class="h2 mb-0">{{ 'tradeInCar.sellTitle' | translate }}</h1>
                        <h1 *ngIf="advertisement" class="h2 mb-0">{{ 'tradeInCar.requestTitle' | translate }}</h1>

                        <div class="form-label  fw-bold pt-3 pb-2">{{ 'tradeInCar.contactInformation' | translate }}
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="sc-condition">{{ 'tradeInCar.salutation' | translate }}<span
                                    class="text-danger">*</span></label>
                            <select formControlName="salutationType" class="form-select" id="sc-condition" required>
                                <option value="meneer">{{ 'tradeInCar.mr' | translate }}</option>
                                <option value="mevrouw">{{ 'tradeInCar.mrs' | translate }}</option>
                                <option value="neutraal">{{ 'tradeInCar.neutral' | translate }}</option>
                            </select>
                            <div *ngIf="requestForm.get('salutationType')!.touched && requestForm.get('salutationType')!.invalid"
                                class="text-danger">
                                {{ 'tradeInCar.salutationRequired' | translate }}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 mb-3">
                                <label class="form-label" for="sc-title">{{ 'tradeInCar.firstName' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="firstName" class="form-control" type="text" id="sc-title"
                                    placeholder="" required>
                                <div *ngIf="requestForm.get('firstName')!.touched && requestForm.get('firstName')!.invalid"
                                    class="text-danger">
                                    {{ 'tradeInCar.firstNameRequired' | translate }}
                                </div>
                            </div>

                            <div class="col-sm-6 mb-3">
                                <label class="form-label" for="sc-title">{{ 'tradeInCar.lastName' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="lastname" class="form-control" type="text" id="sc-title"
                                    placeholder="" required>
                                <div *ngIf="requestForm.get('lastname')!.touched && requestForm.get('lastname')!.invalid"
                                    class="text-danger">
                                    {{ 'tradeInCar.lastNameRequired' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="sc-title">{{ 'tradeInCar.email' | translate }} <span
                                    class="text-danger">*</span></label>
                            <input formControlName="email" class="form-control" type="text" id="sc-title" placeholder=""
                                required>
                            <div *ngIf="requestForm.get('email')!.touched && requestForm.get('email')!.invalid"
                                class="text-danger">
                                <div *ngIf="requestForm.get('email')!.errors!['required']">{{ 'tradeInCar.emailRequired'
                                    | translate }}</div>
                                <div *ngIf="requestForm.get('email')!.errors!['email']">{{ 'tradeInCar.invalidEmail' |
                                    translate }}</div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="sc-title">{{ 'tradeInCar.phone' | translate }} <span
                                    class="text-danger">*</span></label>
                            <input formControlName="phone" appPhoneNumberFormatter class="form-control" type="text"
                                id="sc-title" placeholder="" required>
                            <div *ngIf="requestForm.get('phone')!.touched && requestForm.get('phone')!.invalid"
                                class="text-danger">
                                <div *ngIf="requestForm.get('phone')!.errors!['required']">{{ 'tradeInCar.phoneRequired'
                                    | translate }}</div>
                                <div *ngIf="requestForm.get('phone')!.errors!['minlength']">{{
                                    'tradeInCar.phoneMinLength' | translate }}</div>
                                <div *ngIf="requestForm.get('phone')!.errors!['maxlength']">{{
                                    'tradeInCar.phoneMaxLength' | translate }}</div>
                            </div>
                        </div>

                        <div class="form-label mt-3 fw-bold pt-3 pb-2">{{ 'tradeInCar.tradeInCarDetails' | translate }}
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="sc-title">{{ 'tradeInCar.licensePlate' | translate }} <span
                                    class="text-danger">*</span></label>
                            <input formControlName="licensePlate" class="form-control" type="text" id="sc-title"
                                placeholder="" required>
                            <div *ngIf="requestForm.get('licensePlate')!.touched && requestForm.get('licensePlate')!.invalid"
                                class="text-danger">
                                <div *ngIf="requestForm.get('licensePlate')!.errors!['required']">{{
                                    'tradeInCar.licensePlateRequired' | translate }}</div>
                                <div *ngIf="requestForm.get('licensePlate')!.errors!['minlength']">{{
                                    'tradeInCar.licensePlateMinLength' | translate }}</div>
                                <div *ngIf="requestForm.get('licensePlate')!.errors!['maxlength']">{{
                                    'tradeInCar.licensePlateMaxLength' | translate }}</div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 mb-3">
                                <label class="form-label" for="sc-title">{{ 'tradeInCar.odometer' | translate }} <span
                                        class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input formControlName="odometer" class="form-control" type="number" id="sc-title"
                                        placeholder="" required>
                                    <select formControlName="odometerUnit" class="form-select" required>
                                        <option value="km">km</option>
                                        <option value="mi">mi</option>
                                    </select>
                                </div>
                                <div *ngIf="requestForm.get('odometer')!.touched && requestForm.get('odometer')!.invalid"
                                    class="text-danger">
                                    {{ 'tradeInCar.odometerRequired' | translate }}
                                </div>
                                <div *ngIf="requestForm.get('odometerUnit')!.touched && requestForm.get('odometerUnit')!.invalid"
                                    class="text-danger">
                                    {{ 'tradeInCar.odometerUnitRequired' | translate }}
                                </div>
                            </div>

                            <div class="col-sm-6 mb-3">
                                <label class="form-label" for="sc-condition">{{
                                    'advertisement-list.filters.transmission' | translate }}<span
                                        class="text-danger">*</span></label>
                                <select formControlName="transmissionType" class="form-select" id="sc-condition"
                                    required>
                                    <option value="handgeschakeld">{{ 'tradeInCar.manual' | translate }}</option>
                                    <option value="automaat">{{ 'tradeInCar.automatic' | translate }}</option>
                                </select>
                                <div *ngIf="requestForm.get('transmissionType')!.touched && requestForm.get('transmissionType')!.invalid"
                                    class="text-danger">
                                    {{ 'tradeInCar.transmissionRequired' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label" for="comments">{{ 'tradeInCar.comments' | translate }}</label>
                            <textarea formControlName="comments" class="form-control" id="comments" rows="3"></textarea>
                        </div>

                        <div class="mb-3 form-check">
                            <input formControlName="subscribeNewsletter" class="form-check-input" type="checkbox"
                                id="subscribeNewsletter">
                            <label class="form-check-label" for="subscribeNewsletter">{{
                                'tradeInCar.subscribeNewsletter' | translate }}</label>
                        </div>

                        <button class="btn btn-primary mt-4" type="submit">{{ 'tradeInCar.nextStep' | translate
                            }}</button>
                    </section>
                </form>

                <!-- Photos / video-->
                <section class="card  card-body shadow-sm p-4 mb-4" id="photos" #photos *ngIf="currentStep == 'photos'">
                    <h2 class="h4  mb-4"><i class="fi-image text-primary fs-5 mt-n1 me-2"></i>{{ 'tradeInCar.photos' |
                        translate }}</h2>
                    <div class="alert alert-warning bg-faded-warning border-warning mb-4" role="alert">
                        <div class="d-flex"><i class="fi-alert-circle me-2 me-sm-3"></i>
                            <p class="fs-sm mb-1 text-black">{{ 'tradeInCar.photoUploadInstructions' | translate }}</p>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <input type="file" id="fileInput" (change)="onFileSelected($event)"
                                accept="image/*" hidden>
                            <button class="btn btn-secondary" type="button" (click)="triggerFileInput()">{{
                                'tradeInCar.uploadPhotos' | translate }}</button>
                        </div>
                    </div>
                    <div class="row">
                        <h6 *ngIf="images.length === 0">{{ 'tradeInCar.noPhotosUploaded' | translate }}</h6>
                        <p class="text-danger" *ngIf="images.length < 3">{{ 'tradeInCar.minimumPhotosRequired' | translate }}</p>

                        <div class="col-4 mb-3" *ngFor="let image of images; let i = index">
                            <div class="card">
                                <img [src]="image.url" class="card-img-top" alt="Image Preview" *ngIf="!image.loading">
                                <div class="card-body text-center">
                                    <div *ngIf="image.loading">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">{{ 'tradeInCar.loading' | translate }}</span>
                                        </div>
                                    </div>
                                    <button class="btn btn-danger btn-sm btn-xs" (click)="confirmDelete(i)">{{
                                        'tradeInCar.delete' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-auto">
                            <button class="btn btn-danger mt-4" (click)="cancelTradeRequest()">{{
                                'tradeInCar.cancelRequest' | translate }}</button>

                        </div>
                        <div class="col text-right">
                            <button class="btn btn-primary mt-4" [disabled]="images.length < 3"
                                (click)="finishTradeRequest()">{{ 'tradeInCar.submitRequest' | translate }}</button>
                        </div>
                    </div>
                </section>

                <!-- Photos / video-->
                <section class="card  card-body shadow-sm p-4 mb-4" id="finish" #finish *ngIf="currentStep == 'finish'">
                    <h2 class="h4  mb-4">{{ 'tradeInCar.thankYou' | translate }}</h2>
                    <div class="alert alert-success bg-faded-success border-success mb-4" role="alert">
                        <div class="d-flex"><i class="fi-alert-circle me-2 me-sm-3"></i>
                            <p class="fs-sm mb-1 text-black">{{ 'tradeInCar.requestSubmitted' | translate }}</p>
                        </div>
                    </div>
                </section>
            </div>

            <aside class="col-lg-3 d-none d-lg-block" *ngIf="advertisement">
                <div class="sticky-top pt-4">
                    <!-- Card container -->
                    <div class="card">
                        <div class="card-body">
                            <!-- Card title -->
                            <h5 class="card-title">{{ 'tradeInCar.interestVehicle' | translate }}</h5>
                            <!-- Vehicle make and model -->
                            <h6 class="card-subtitle mb-2 text-muted">
                                {{advertisement.vehicle.make}} {{advertisement.vehicle.model}}
                            </h6>
                            <!-- Vehicle image -->
                            <img class="card-img-top img-fluid mt-3" [src]="advertisement.images[0]" alt="Car">
                            <!-- Price -->
                            <p class="card-text">
                                {{advertisement.consumerPrice.amount | currencyFormat}}
                            </p>
                        </div>
                    </div>
                </div>
            </aside>
        </div>

    </div>
</main>