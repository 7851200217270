import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Advertisements } from "../models/advertisements.model";
import { Advertisement } from "../models/advertisement.model";
import { SearchOptions } from "../models/searchOptions.model";
import { Counters } from "../models/counters.model";
import { EmailSubscription } from "../models/emailSubscription";
import { OwnershipTax } from "../models/ownershipTax";
import { TradeRequestBody } from "../models/tradeRequestBoday.model";
import { TranslateService } from "@ngx-translate/core";
import { TradeRequest } from "../models/tradeRequest.model";
import { RequestImage } from "../models/uploadImage.model";
import { Reviews } from "../models/reviews.model";

@Injectable()
export class ApiService {
  private api_base: string = environment.url;
  private RequestHeaders;
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {
    // Get current locale
    const currentLang = this.translate.currentLang;
    // Initialize headers
    this.RequestHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .append('Accept-Language', currentLang);
  }

  // GET
  public getAdvertisements(limit: number, page: number, orderBy?: any, filter?: [any], customFilter?: [{}]) {
    let Params = new HttpParams()
    Params = Params.append('limit', String(limit));
    Params = Params.append('pageNumber', String(page));
    if (orderBy) Params = Params.append('orderBy', JSON.stringify(orderBy));
    if (filter) Params = filter && Params.append('filter', JSON.stringify(filter));
    if (customFilter) Params = customFilter && Params.append('customFilter', JSON.stringify(customFilter));
    return this.http.get<Advertisements>(this.api_base + '/advertisement/v1/', { headers: this.RequestHeaders, params: Params });
  }

  public getAdvertisement(id: number, fields?: []) {
    let Params = new HttpParams()
    if (fields) Params = fields && Params.append('fields', JSON.stringify(fields));
    return this.http.get<Advertisement>(this.api_base + '/advertisement/v1/' + id, { headers: this.RequestHeaders, params: Params });
  }

  public getCounters() {
    let Params = new HttpParams()
    return this.http.get<Counters>(this.api_base + '/advertisement/v1/counters', { headers: this.RequestHeaders, params: Params });
  }

  public getSearchOptions(filter?: [any]) {
    let Params = new HttpParams()
    if (filter) Params = filter && Params.append('filter', JSON.stringify(filter));
    return this.http.get<SearchOptions>(this.api_base + '/advertisement/v1/search-options', { headers: this.RequestHeaders, params: Params });
  }

  public getVehicleOwnershipTax(id: number, province: string) {
    let Params = new HttpParams()
    if (province) Params = Params.append('province', province);
    return this.http.get<OwnershipTax>(this.api_base + '/advertisement/v1/' + id + '/vehicle-ownership-tax', { headers: this.RequestHeaders, params: Params });
  }

  public getReviews(limit: number, page: number, orderBy?: any, filter?: [any], customFilter?: [{}]) {
    let Params = new HttpParams()
    Params = Params.append('limit', String(limit));
    Params = Params.append('pageNumber', String(page));
    if (orderBy) Params = Params.append('orderBy', JSON.stringify(orderBy));
    if (filter) Params = filter && Params.append('filter', JSON.stringify(filter));
    if (customFilter) Params = customFilter && Params.append('customFilter', JSON.stringify(customFilter));
    return this.http.get<Reviews>(this.api_base + '/communication/v1/review', { headers: this.RequestHeaders, params: Params });
  }

  // POST
  public subscribeToNewsletter(email: string) {
    // Set post params
    const postParams = {
      email: email
    }
    return this.http.post<EmailSubscription>(this.api_base + '/communication/v1/newsletter/subscription', postParams, { headers: this.RequestHeaders });
  }

  public createTradeRequest(parameters: TradeRequestBody) {
    return this.http.post<TradeRequest>(this.api_base + '/request/v1/trade', parameters, { headers: this.RequestHeaders });
  }

  public addImageToRequest(id: number, tradeRequestToken: string, parameters: FormData) {
    // Add X-trade-request-token to headers
    const RequestHeaders = this.RequestHeaders.append('X-trade-request-token', tradeRequestToken ?? '');
    return this.http.post<RequestImage>(this.api_base + '/request/v1/trade/' + id + '/image', parameters, { headers: RequestHeaders });
  }

  // PUT
  public verifyNewsletterSubscription(email: string, hash: string) {
    // Set post params
    const postParams = {
      email: email,
      hash: hash
    }
    return this.http.put<EmailSubscription>(this.api_base + '/communication/v1/newsletter/subscription/', postParams, { headers: this.RequestHeaders });
  }

  public finishTradeRequest(id: number, tradeRequestToken: string) {
    // Add X-trade-request-token to headers
    const RequestHeaders = this.RequestHeaders.append('X-trade-request-token', tradeRequestToken ?? '');
    return this.http.put<EmailSubscription>(this.api_base + '/request/v1/trade/' + id + '/finish', null, { headers: RequestHeaders });
  }

  public receivedTradeRequest(id: number, tradeRequestToken: string) {
    // Add X-trade-request-token to headers
    const RequestHeaders = this.RequestHeaders.append('X-trade-request-token', tradeRequestToken ?? '');
    return this.http.put<EmailSubscription>(this.api_base + '/request/v1/trade/' + id + '/received', null, { headers: RequestHeaders });
  }

  // DELETE
  public unsubscribeNewsletterSubscription(email: string, hash: string) {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('hash', hash);
    return this.http.delete<EmailSubscription>(this.api_base + '/communication/v1/newsletter/subscription/', { headers: this.RequestHeaders, params: params });
  }

  public deleteImageFromRequest(id: number, tradeRequestToken: string, imageId: number) {
    // Add X-trade-request-token to headers
    const RequestHeaders = this.RequestHeaders.append('X-trade-request-token', tradeRequestToken ?? '');
    return this.http.delete<EmailSubscription>(this.api_base + '/request/v1/trade/' + id + '/image/' + imageId, { headers: RequestHeaders });
  }
}