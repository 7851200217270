<footer class="footer bg-faded-dar bg-dark">
    <div class="border-bottom border-light py-4">
        <div class="container d-sm-flex align-items-center justify-content-between"><!-- <a class="d-inline-block"
                routerLink=""><img src="../../../assets/images/keurmerken.png" width="350" alt="logo"></a> -->
            <div class="d-flex pt-3 pt-sm-0">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary dropdown-toggle" id="languageDropdown" ngbDropdownToggle>
                        {{ getCurrentLanguageName() }}
                    </button>
                    <div class="dropdown-menu dropdown-menu w-100" ngbDropdownMenu aria-labelledby="languageDropdown">
                        <a class="dropdown-item" href="javascript:void(0);" (click)="selectLanguage('nl')"
                            [ngClass]="{ 'active': isLanguageSelected('nl') }">Nederlands</a>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="selectLanguage('en')"
                            [ngClass]="{ 'active': isLanguageSelected('en') }">English</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-4 pb-3 pt-lg-5 pb-lg-4">
        <div class="row pt-2 pt-lg-0">
            <div class="col-lg-3 col-md-3 col-sm-6 mb-2 mb-sm-4">
                <h3 class="fs-base text-light">{{'footer.address.title' | translate}}</h3>
                <ul class="list-unstyled fs-sm">
                    <li>Vlijtseweg 202</li>
                    <li>7317 AN Apeldoorn (Noord)</li>
                </ul>
                <h3 class="fs-base text-light">{{'footer.openingHours.title' | translate}}</h3>
                <ul class="list-unstyled fs-sm">
                    <table>
                        <tr>
                            <td>{{'footer.openingHours.days' | translate}}:&nbsp;&nbsp;</td>
                            <td>09:00 - 17:00</td>
                        </tr>
                        <tr>
                            <td>{{'footer.openingHours.saturday' | translate}}:</td>
                            <td>09:00 - 16:30</td>
                        </tr>
                    </table>
                    <p>{{'footer.openingHours.outsideOpeningHours' | translate}}</p>
                </ul>
                <p>
                    <a href="/privacyverklaring" class="text-light">{{'footer.privacyStatement' | translate}}</a>
                </p>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6 mb-2 mb-sm-4">
                <h3 class="fs-base text-light">{{'footer.phone.title' | translate}}</h3>
                <a class="d-flex align-items-center text-decoration-none mb-2" href="tel:0888833250">
                    <i class="fi-phone me-2"></i><span class="text-light">088 - 88 33 250 ({{'footer.phone.general' |
                        translate}})</span></a>

                <a class="d-flex align-items-center text-decoration-none mb-2" href="tel:3165105582">
                    <i class="fi-device-mobile me-2"></i><span class="text-light">06 - 510 55 825 (Ertjo Buitenhuis
                        JR)</span></a>

                <h3 class="fs-base text-light mt-4">Email</h3>
                <a class="d-flex align-items-center text-decoration-none mb-2" href="mailto:verkoop@auto-net.nl"><i
                        class="fi-mail me-2"></i><span class="text-light">verkoop&#64;auto-net.nl</span></a>
            </div>

            <div class="col-lg-3 offset-xl-1  pb-2 mb-4">
                <h3 class="h5 text-light mb-2">{{'footer.newsletter.title' | translate}}</h3>
                <p class="fs-sm text-light opacity-70">{{'footer.newsletter.description' | translate}}</p>
                <form class="form-group form-group-light w-100" [formGroup]="emailSubscriptionForm"
                    (ngSubmit)="subscribeToNewsletter()">
                    <div class="input-group input-group-sm"><span class="input-group-text"> <i
                                class="fi-mail"></i></span>
                        <input formControlName="email" class="form-control" type="text" placeholder="Email">
                    </div>
                    <button [disabled]="!emailSubscriptionForm.valid" class="btn btn-primary btn-sm"
                        type="submit">{{'footer.newsletter.subscribe' |
                        translate}}</button>
                </form>

                <h3 class="fs-base text-light mt-4">{{'footer.socialMedia.reviews' | translate}}</h3>
                <iframe frameborder="0" allowtransparency="true" src="https://www.klantenvertellen.nl/retrieve-widget.html?color=dark&allowTransparency=true&button=false&lang=nl&tenantId=99&locationId=1052512" width="400" height="122"></iframe>
            </div>
        </div>
    </div>
    <div class="container d-lg-flex align-items-center justify-content-between fs-sm pb-3">
        <p class="text-center text-lg-start order-lg-1 mb-lg-0"><span class="text-light opacity-50">&copy; {{year}}
                {{'footer.copyright' | translate}} </span>
        </p>
    </div>
</footer>

<!-- Back to top button-->
<a (click)="scrollToTop()" class="btn-scroll-top" href="javascript:void(0);" data-scroll><span
        class="btn-scroll-top-tooltip text-muted fs-sm me-2">{{'footer.backToTop' | translate}}</span><i
        class="btn-scroll-top-icon fi-chevron-up">
    </i></a>