<div class="container my-4">
    <div class="row mb-2">
        <div class="col">
            <h4>{{ 'print.autoNetBV' | translate }}</h4>
            <p><b>{{ 'contact.visitAddress' | translate }}</b>: <br> Vlijtseweg 202, 7317 AN Apeldoorn (Noord)</p>
            <p>
                {{ 'contact.phone' | translate }}: 088-8833250<br>
                {{ 'contact.mobile' | translate }}: 06-51055825<br>
                {{ 'contact.email' | translate }}: verkoop at auto-net.nl
            </p>
        </div>
        <div class="col text-end">
            <img src="../../../assets/images/vlijtseweg.png" alt="Header Image" class="img-fluid"
                style="max-width: 200px;" />
        </div>
    </div>

    <hr>

    <div class="row mb-2">
        <div class="col-4">
            <img [src]="advertisement.images[0]" class="img-fluid" style="max-width: 200px;" />
        </div>

        <div class="col-8">
            <div class="row">
                <div class="col-2 mb-1" *ngFor="let image of advertisement.images">
                    <img [src]="image" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-12">
            <h4 class="text-primary" *ngIf="advertisement.consumerPrice">
                {{advertisement.vehicle.make}} {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}
                {{advertisement.consumerPrice.amount | currencyFormat}}
            </h4>
            <h4 class="text-primary" *ngIf="!advertisement.consumerPrice">
                {{advertisement.vehicle.make}} {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}
                <span class="text-danger"
                    *ngIf="advertisement.consumerPrice == null && advertisement.isSold === true && advertisement.isReserved == false">{{
                    'advertisement-list.sold' |
                    translate }}</span>
                <span class="text-danger"
                    *ngIf="advertisement.consumerPrice == null && advertisement.isReserved === true">{{
                    'advertisement-list.reserved' |
                    translate }}</span>
            </h4>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-6">
            <div class="row">
                <div class="col">
                    <h6 class="text-primary">{{ 'print.occasionDetails' | translate }}</h6>
                </div>
            </div>
            <table class="table table-sm table-bordered small-table">
                <tbody>
                    <tr>
                        <td>{{ 'print.licensePlate' | translate }}</td>
                        <td>{{advertisement.vehicle.licensePlate | registration}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.vehicleType' | translate }}</td>
                        <td>{{advertisement.vehicle.vehicleType}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.dateFirstRegistration' | translate }}</td>
                        <td>{{advertisement.vehicle.dateFirstRegistration}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.buildYear' | translate }}</td>
                        <td>{{advertisement.vehicle.buildYear}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.fuelType' | translate }}</td>
                        <td>{{advertisement.vehicle.fuelType}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.bodyType' | translate }}</td>
                        <td>{{advertisement.vehicle.bodyType}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.numberOfDoors' | translate }}</td>
                        <td>{{advertisement.vehicle.numberOfDoors}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.transmissionType' | translate }}</td>
                        <td>{{advertisement.vehicle.transmissionType}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.color' | translate }}</td>
                        <td>{{advertisement.vehicle.color}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.interiorTrimColor' | translate }}</td>
                        <td>{{advertisement.vehicle.interiorTrimColor}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.upholsteryType' | translate }}</td>
                        <td>{{advertisement.vehicle.upholsteryType}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.odometer' | translate }}</td>
                        <td>{{advertisement.vehicle.odometer}} {{advertisement.vehicle.odometerUnit}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.nap' | translate }}</td>
                        <td>{{advertisement.vehicle.hasValidOdometer | yesNo}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.apkExpires' | translate }}</td>
                        <td>{{advertisement.vehicle.dateApkExpires}}</td>
                    </tr>
                    <tr>
                        <td>{{ 'print.numberOfKeys' | translate }}</td>
                        <td>{{advertisement.vehicle.numberOfKeys}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-6">
            <div class="mb-3">
                <div class="row">
                    <div class="col">
                        <h6 class="text-primary">{{ 'print.technicalDetails' | translate }}</h6>
                    </div>
                </div>

                <table class="table table-sm table-bordered small-table">
                    <tbody>
                        <tr>
                            <td>{{ 'print.numberOfCylinders' | translate }}</td>
                            <td>{{advertisement.vehicle.numberOfCylinders}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.cylinderVolume' | translate }}</td>
                            <td>{{advertisement.vehicle.cylinderVolume}} cm³</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.powerKw' | translate }}</td>
                            <td>{{advertisement.vehicle.powerKw}} kW</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.emptyVehicleWeight' | translate }}</td>
                            <td>{{advertisement.vehicle.emptyVehicleWeight}} kg</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.maxTowingWeightBraked' | translate }}</td>
                            <td>{{advertisement.vehicle.maxTowingWeightBraked}} kg</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.maxTowingWeightUnbraked' | translate }}</td>
                            <td>{{advertisement.vehicle.maxTowingWeightUnbraked}} kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <div class="row">
                    <div class="col">
                        <h6 class="text-primary">{{ 'print.otherDetails' | translate }}</h6>
                    </div>
                </div>
                <table class="table table-sm table-bordered small-table">
                    <tbody>
                        <tr>
                            <td>{{ 'print.co2Emission' | translate }}</td>
                            <td>{{advertisement.vehicle.co2Emission}} g/km</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.energyLabel' | translate }}</td>
                            <td>{{advertisement.vehicle.energyLabel}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.usageCity' | translate }}</td>
                            <td>{{advertisement.vehicle.usageCity}} L/100km</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.usageHighway' | translate }}</td>
                            <td>{{advertisement.vehicle.usageHighway}} L/100km</td>
                        </tr>
                        <tr>
                            <td>{{ 'print.usageAverage' | translate }}</td>
                            <td>{{advertisement.vehicle.usageAverage}} L/100km</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div style="height:80px;"></div>

    <div class="row">
        <div class="col">
            <h6 class="text-primary">{{ 'print.accessories' | translate }}</h6>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <ul class="accessories-list">
                <li *ngFor="let accessory of advertisement.vehicle.accessories">{{accessory}}</li>
            </ul>
        </div>
    </div>
</div>