<title>Auto-net - Reviews</title>

<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
        <div class="row py-md-1">
            <div class="col-md-12 main-content mt-2">
                <!-- Main content -->
                <h1>{{ 'reviews.title' | translate }}</h1>

                <div *ngFor="let review of reviews">
                    <!-- Review -->
                    <div class="d-flex justify-content-between mb-3">
                        <div class="d-flex align-items-center pe-2">
                            <div class="ps-2">
                                <h6 class="fs-base mb-0">{{review.author}}</h6>
                                <div class="star-rating">
                                    <ng-container *ngFor="let star of getStars(review.rating)">
                                        <i class="star-rating-icon" [ngClass]="{'fi-star-filled active': star, 'fi-star': !star}"></i>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <span class="text-muted fs-sm">{{review.publicationDate.date | date}}</span>
                    </div>
                    <p><b>{{review.experienceShort}}</b></p>
                    <p>{{review.experience}}</p><br>
                </div>
            </div>
        </div>
    </div>
</div>