<div class="modal-header">
  <h5 class="modal-title" id="newsletterModalLabel">{{ 'footer.newsletter.title' | translate }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">
  <p>{{ 'footer.newsletter.description' | translate }}</p>
  <form [formGroup]="emailSubscriptionForm">
    <div class="mb-3">
      <label for="email" class="form-label">{{ 'footer.newsletter.placeholder' | translate }}</label>
      <input type="email" class="form-control" id="email" formControlName="email"
        placeholder="{{ 'footer.newsletter.placeholder' | translate }}" required>
      <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="text-danger">
        <div *ngIf="email?.errors?.['required']">{{ 'footer.newsletter.emailRequired' | translate }}</div>
        <div *ngIf="email?.errors?.['email']">{{ 'footer.newsletter.invalidEmail' | translate }}</div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">{{ 'footer.newsletter.close' | translate
    }}</button>
  <button [disabled]="!emailSubscriptionForm.valid" type="button" class="btn btn-primary" (click)="subscribe()">{{ 'footer.newsletter.subscribe' | translate
    }}</button>
</div>  