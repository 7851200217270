import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Data as Advertisement, Comment } from 'src/app/models/advertisement.model';
import { Data as OwnershipTax } from 'src/app/models/ownershipTax';
import { ApiService } from 'src/app/services/api.service';
import { CarouselLibConfig, Image } from '@ks89/angular-modal-gallery';
import { ToastrService } from 'ngx-toastr';
import { MetaService } from 'src/app/services/meta.service';  // Adjust the path as needed
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-advertisement-detail',
  templateUrl: './advertisement-detail.component.html',
  styleUrls: ['./advertisement-detail.component.scss']
})
export class AdvertisementDetailComponent implements OnInit {
  public advertisement!: Advertisement;
  public ownershipTax!: OwnershipTax;
  private lastView: any;

  public config: CarouselLibConfig = {
    carouselConfig: {
      maxHeight: '',
      maxWidth: '100%',
      showArrows: true,
      objectFit: 'contain',
      keyboardEnable: true,
      modalGalleryEnable: true,
    },
    carouselPlayConfig: {
      autoPlay: false,
      interval: 5000,
      pauseOnHover: true,
    },
    carouselPreviewsConfig: {
      visible: true,
      clickable: true,
      number: 6,
    },
  };

  get vehicleComments(): Comment[] {
    return this.advertisement?.comments?.filter(comment => comment.type === 'voertuig') || [];
  }

  public images: Image[] = [];

  vehicleOwnershipTaxForm = new FormGroup({
    province: new FormControl({ value: null, disabled: false }, Validators.required),
  });

  @ViewChild('printSection') printSection!: ElementRef;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private metaService: MetaService,
    private translate: TranslateService
  ) {
    // Get lastView from local storage
    if (localStorage.getItem('lastView') != null) {
      this.lastView = JSON.parse(localStorage.getItem('lastView')!);
    }
  }

  ngOnInit() {
    // Get the advertisement id from the route
    this.route.paramMap.subscribe((params) => {
      const advertisementId = params.get('id');
      this.getAdvertisementDetail(Number(advertisementId));
    });
  }

  public print() {
    const printContents = this.printSection.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // to ensure the original state is restored properly
  }

  private getAdvertisementDetail(advertisementId: number) {
    this.apiService.getAdvertisement(advertisementId).subscribe((response) => {
      this.advertisement = response.data;
      this.images = this.convertUrlsToImages(this.advertisement.images);
      // Update last viewed advertisement in local storage
      localStorage.setItem('lastViewedAdvertisement', JSON.stringify(this.advertisement));

      // Update meta tags
      this.updateMetaTags();
    }, error => {
      if (error.error.errors[0] == 'Data not available anymore') {
        this.toastr.error(
          this.translate.instant('advertisement-detail.unavailable'),
          this.translate.instant('advertisement-detail.error'));
      } else {
        this.toastr.error(
          this.translate.instant('advertisement-detail.somethingWentWrong'),
          this.translate.instant('advertisement-detail.error')
        );
      }
      this.router.navigate(['/']);
      return;
    });
  }

  private updateMetaTags() {
    const params = {
      carMake: this.advertisement.vehicle.make,
      carModel: this.advertisement.vehicle.model,
      carType: this.advertisement.vehicle.type,
      carYear: this.advertisement.vehicle.buildYear,
      carId: this.advertisement.id,
      carImage: this.advertisement.images[0]
    };

    this.metaService.updateMetaTags({
      titleKey: 'advertisement-detail.seo.title',
      descriptionKey: 'advertisement-detail.seo.description',
      keywordsKey: 'advertisement-detail.seo.keywords',
      authorKey: 'advertisement-detail.seo.author',
      ogTitleKey: 'advertisement-detail.seo.ogTitle',
      ogDescriptionKey: 'advertisement-detail.seo.ogDescription',
      ogImageKey: 'advertisement-detail.seo.ogImage',
      ogUrlKey: 'advertisement-detail.seo.ogUrl',
    }, params);
  }

  public startRequest() {
    // Navigate to request page
    this.router.navigate(['/inruilen', this.advertisement.id]);
  }

  public convertUrlsToImages(urls: string[]): Image[] {
    return urls.map((url, index) => new Image(
      index,
      {
        img: url,
        description: `Car image ${index + 1} description`
      },
      {
        img: url,
        title: `Car image ${index + 1} title`,
        alt: `Car image ${index + 1} alt`,
        ariaLabel: `Car image ${index + 1} aria-label`
      }
    ));
  }

  public submitOwnershipTaxForm() {
    if (this.vehicleOwnershipTaxForm.controls.province.value === null) {
      return;
    }

    this.apiService.getVehicleOwnershipTax(this.advertisement.id, this.vehicleOwnershipTaxForm.controls.province.value).subscribe((response) => {
      this.ownershipTax = response.data;
    });
  }

  public backToOverview() {
    this.router.navigate([this.lastView?.path || '/']);
  }

  // Helper function to get wrapped index
  private getWrappedIndex(currentIndex: number, step: number, arrayLength: number): number {
    const newIndex = (currentIndex + step + arrayLength) % arrayLength;
    return newIndex;
  }

  public nextAdvertisement(): void {
    this.navigateAdvertisement(1); // Pass step as 1 for next
  }

  public previousAdvertisement(): void {
    this.navigateAdvertisement(-1); // Pass step as -1 for previous
  }

  private navigateAdvertisement(step: number): void {
    if (this.lastView?.advertisementIds) {
      const currentIndex = this.lastView.advertisementIds.indexOf(this.advertisement.id);
      if (currentIndex !== -1) {
        let newIndex = this.getWrappedIndex(currentIndex, step, this.lastView.advertisementIds.length);

        if ((step > 0 && newIndex === 0) || (step < 0 && newIndex === this.lastView.advertisementIds.length - 1)) {
          // Adjust the page based on direction before fetching more ads
          this.lastView.page += step;
          this.fetchAdvertisements(() => {
            // This callback is called after new data is fetched
            // Recalculate newIndex with updated advertisement IDs
            newIndex = this.getWrappedIndex(currentIndex, step, this.lastView.advertisementIds.length);
            const newAdvertisementId = this.lastView.advertisementIds[newIndex];
            this.router.navigate(['/occasion', newAdvertisementId]);
          });
        } else {
          // Normal navigation within current set of ads
          const newAdvertisementId = this.lastView.advertisementIds[newIndex];
          this.router.navigate(['/occasion', newAdvertisementId]);
        }
      } else {
        // Current ad not found, fetch data again
        this.fetchAdvertisements(() => this.navigateAdvertisement(step));
      }
    } else {
      this.toastr.error('No advertisements available to navigate.', 'Error');
    }
  }

  private fetchAdvertisements(callback: () => void): void {
    this.apiService.getAdvertisements(
      10,
      this.lastView.page,
      this.lastView.order,
      this.lastView.filter,
      this.lastView.customFilter
    ).subscribe({
      next: (data) => {
        const advertisementIds = data.data.map(ad => ad.id);
        if (advertisementIds.length > 0) {
          this.lastView.advertisementIds = advertisementIds;
          localStorage.setItem('lastView', JSON.stringify(this.lastView));
          callback(); // Call the callback to continue navigation after updating data
        } else {
          this.toastr.warning('No more advertisements found.', 'End of List');
        }
      },
      error: (err) => {
        this.toastr.error('Failed to load advertisements.', 'Network Error');
        console.error('Error fetching advertisements:', err);
      }
    });
  }
}
