import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ModalStateService } from 'src/app/services/modal-state.service';

@Component({
  selector: 'app-nieuwsbrief-modal',
  templateUrl: './nieuwsbrief-modal.component.html',
  styleUrls: ['./nieuwsbrief-modal.component.scss']
})
export class NieuwsbriefModalComponent implements OnInit {
  emailSubscriptionForm = new FormGroup({
    email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private modalStateService: ModalStateService,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void { }

  get email() {
    return this.emailSubscriptionForm.get('email');
  }

  public close() {
    this.modalStateService.setModalShown();
    this.activeModal.close();
  }

  static open(modalService: NgbModal): void {
    modalService.open(NieuwsbriefModalComponent, { centered: true });
  }

  public subscribe(): void {
    if (this.emailSubscriptionForm.invalid) return;
    if (this.emailSubscriptionForm.controls.email.value === null) return;

    this.apiService.subscribeToNewsletter(this.emailSubscriptionForm.controls.email.value).subscribe(data => {
      if (data.success === true) {
        this.toastr.success('Er is een email verstuurd naar ' + this.emailSubscriptionForm.controls.email.value + ' om je inschrijving te bevestigen.');
        this.activeModal.close();
      } else {
        this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.');
      }
      this.emailSubscriptionForm.reset();
    }, error => {
      this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.');
      this.emailSubscriptionForm.reset();
    });
  }
}
