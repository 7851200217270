<div *ngIf="!showDevPassword" class="bg-light" id="app-container">
  <app-header></app-header>
  <div id="main-content">
    <app-sidebar></app-sidebar>
    <div id="content">
      <!-- Router outlet for displaying main-level components/pages -->
      <router-outlet> </router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<div *ngIf="showDevPassword" class="d-flex justify-content-center align-items-center vh-100">
  <div class="text-center">
    <h1>Development Only</h1>
    <div class="mb-3">
      <input [(ngModel)]="devPassword"  type="password" class="form-control form-control-sm mx-auto" style="width: 300px;"
        placeholder="Enter Password">
    </div>
    <button (click)="submitDevPassword()" class="btn btn-primary btn-sm" style="width: 300px;">Submit</button>
  </div>
</div>