<!-- Catalog grid view -->
<div class="col-lg-12" *ngIf="carOfTheWeek == false">
    <div class="row pt-2">
        <div class="col-12">
            <ngb-alert *ngIf="advertisements.length === 0" [dismissible]="false" type="accent"
                class="mt-3 alert alert-accent d-flex">
                <i class="fi-bell me-2 me-sm-3 lead"></i>
                <div>Er zijn geen resultaten gevonden in deze categorie.</div>
            </ngb-alert>
        </div>

        <div class="col-12 col-md-4 mb-4" *ngFor="let advertisement of advertisements"
            [routerLink]="['/occasion', advertisement.id]" [attr.href]="'/occasion/' + advertisement.id">
            <div class="card card-hover h-100">
                <div class="tns-carousel-wrapper card-img-top card-img-hover">
                    <a class="img-overlay"></a>

                    <div *ngIf="advertisement.vehicle.hasValidOdometer === true"
                        class="position-absolute start-0 top-0 pt-2 ps-2">
                        <img class="d-table mb-1 bg-white rounded" src="../../../assets/images/nap-check.png"
                            alt="NAP Check" width="60">
                    </div>

                    <button (click)="addToFavorites(advertisement)"
                        *ngIf="favoritesService.isSaved(advertisement.id) === false"
                        class="btn btn-icon btn-light btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                        type="button" placement="left" ngbTooltip="{{ 'advertisement-list.compare' | translate }}">
                        <i class="fi-heart"></i>
                    </button>

                    <button (click)="removeFromFavorites(advertisement)"
                        *ngIf="favoritesService.isSaved(advertisement.id) === true"
                        class="btn btn-icon btn-danger btn-xs text-white rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                        type="button" placement="left"
                        ngbTooltip="{{ 'advertisement-list.removeCompare' | translate }}">
                        <i class="fi-heart"></i>
                    </button>

                    <ngx-slick-carousel class="carousel" [config]="config">
                        <div ngxSlickItem>
                            <img [src]="advertisement.image" class="card-img-top" alt="Image">
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between pb-1">
                        <span class="fs-sm me-3">{{advertisement.vehicle.buildYear}}</span>
                    </div>
                    <h3 class="h6 mb-1">
                        <a class="nav-link">{{advertisement.vehicle.make}}
                            {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}</a>
                    </h3>
                    <div class="text-primary fw-bold mb-1">
                        <span *ngIf="advertisement.consumerPrice"> {{advertisement.consumerPrice.amount |
                            currencyFormat}}</span>
                        <span class="text-danger"
                            *ngIf="!advertisement.consumerPrice && advertisement.isSold === true && advertisement.isReserved === false">{{'advertisement-list.sold'
                            |
                            translate}}</span>
                        <span class="text-danger"
                            *ngIf="!advertisement.consumerPrice && advertisement.isReserved === true">{{'advertisement-list.reserved'
                            |
                            translate}}</span>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0">
                    <div class="border-top pt-3">
                        <div class="row g-2">
                            <div class="col me-sm-1">
                                <div class="bg-primary rounded text-center w-100 h-100 p-2">
                                    <i class="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                                    <span class="fs-xs text-light">{{advertisement.vehicle.odometer | number}}
                                        {{advertisement.vehicle.odometerUnit}}</span>
                                </div>
                            </div>
                            <div class="col me-sm-1">
                                <div class="bg-primary rounded text-center w-100 h-100 p-2">
                                    <i class="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                                    <span class="fs-xs text-light">{{advertisement.vehicle.transmissionType}}</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="bg-primary rounded text-center w-100 h-100 p-2">
                                    <i class="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                                    <span class="fs-xs text-light">{{advertisement.vehicle.fuelType}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Catalog grid view -->
<div class="col-lg-12 pt-2 pb-2" *ngIf="carOfTheWeek == true">
    <!-- Light card on dark background: Horizontal -->
    <div class="card card-hover card-horizontal" *ngFor="let advertisement of advertisements"
        [routerLink]="['/occasion', advertisement.id]" [attr.href]="'/occasion/' + advertisement.id">
        <div class="card-img-top card-img-hover" style="background-image: url({{advertisement.image}});">
            <a class="img-overlay"></a>
            <div class="position-absolute start-0 top-0 pt-3 ps-3">
                <span class="d-table badge bg-info">{{'home.carOfTheWeek' | translate}}</span>
            </div>

            <button (click)="addToFavorites(advertisement)" *ngIf="favoritesService.isSaved(advertisement.id) === false"
                class="btn btn-icon btn-light btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                type="button" placement="left" ngbTooltip="{{ 'advertisement-list.compare' | translate }}">
                <i class="fi-heart"></i>
            </button>

            <button (click)="removeFromFavorites(advertisement)"
                *ngIf="favoritesService.isSaved(advertisement.id) === true"
                class="btn btn-icon btn-danger btn-xs text-primary rounded-circle position-absolute end-0 top-0 mt-3 me-3"
                type="button" placement="left" ngbTooltip="{{ 'advertisement-list.removeCompare' | translate }}">
                <i class="fi-heart"></i>
            </button>

        </div>
        <div class="card-body">
            <div class="d-flex align-items-center justify-content-between pb-1">
                <span class="fs-sm me-3">{{advertisement.vehicle.buildYear}}</span>
            </div>
            <h3 class="h6 mb-1">
                <a [routerLink]="['occasion', advertisement.id]" [attr.href]="'/occasion/' + advertisement.id"
                    class="nav-link">{{advertisement.vehicle.make}}
                    {{advertisement.vehicle.model}} {{advertisement.vehicle.type}}</a>
            </h3>

            <div class="text-primary fw-bold mb-1">{{advertisement.consumerPrice?.amount | currencyFormat}}</div>

            <div class="fs-sm text opacity-70">
                <span *ngFor="let accessory of advertisement.vehicle.accessories?.slice(0, 14)"
                    class="badge bg-secondary me-1 mb-1">
                    {{ accessory }}
                </span>
            </div>

            <div class="border-top border-light mt-3 pt-3">
                <div class="row g-2">
                    <div class="col me-sm-1">
                        <div class="bg-primary rounded text-center w-100 h-100 p-2">
                            <i class="fi-dashboard d-block h4 text-light mb-0 mx-center"></i>
                            <span class="fs-xs text-light">{{advertisement.vehicle.odometer | number}}
                                {{advertisement.vehicle.odometerUnit}}</span>
                        </div>
                    </div>
                    <div class="col me-sm-1">
                        <div class="bg-primary rounded text-center w-100 h-100 p-2">
                            <i class="fi-gearbox d-block h4 text-light mb-0 mx-center"></i>
                            <span class="fs-xs text-light">{{advertisement.vehicle.transmissionType}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="bg-primary rounded text-center w-100 h-100 p-2">
                            <i class="fi-petrol d-block h4 text-light mb-0 mx-center"></i>
                            <span class="fs-xs text-light">{{advertisement.vehicle.fuelType}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>