import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  public year = new Date().getFullYear();

  emailSubscriptionForm = new FormGroup({
    email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
  });

  ngOnInit(): void {
  }

  public selectLanguage(lang: string) {
    this.translateService.use(lang);
  }

  public getCurrentLanguageName(): string {
    return this.translateService.currentLang === 'nl' ? 'Nederlands' : 'English';
  }

  public isLanguageSelected(lang: string): boolean {
    return this.translateService.currentLang === lang;
  }

  public subscribeToNewsletter() {
    if (this.emailSubscriptionForm.invalid) return;
    if (this.emailSubscriptionForm.controls.email.value === null) return;

    this.apiService.subscribeToNewsletter(this.emailSubscriptionForm.controls.email.value).subscribe(data => {
      if (data.success === true) {
        this.toastr.success('Er is een email verstuurd naar ' + this.emailSubscriptionForm.controls.email.value + ' om je inschrijving te bevestigen.');
      } else {
        this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.');
      }
      this.emailSubscriptionForm.reset();
    }, error => {
      this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.');
      this.emailSubscriptionForm.reset();
    });
  }

  public scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
