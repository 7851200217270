import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: any): string {
    if (value == null) {
      return '';
    }

    // Convert the number to a formatted Euro currency string
    let formatted = value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    formatted = formatted.substring(0, formatted.length - 3);  // Remove the decimal part
    
    return `€ ${formatted},-`;
  }
}
