import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './404.component.html',
  styleUrl: './404.component.scss'
})
export class NotFoundComponent implements OnInit {

  constructor(
    private metaService: MetaService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.setMetaTags();
  }

  private setMetaTags() {
    this.metaService.updateMetaTags({
      titleKey: 'notFound.seo.title'
    });
  }
}