import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalStateService {
  private storageKey = 'lastShownModalDate';
  private weekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // Number of milliseconds in a week

  constructor() { }

  public shouldShowModal(): boolean {
    const lastShownModalDate = localStorage.getItem(this.storageKey);
    if (!lastShownModalDate) {
      return true;
    }
    const lastShownDate = new Date(lastShownModalDate);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - lastShownDate.getTime();
    return differenceInTime >= this.weekInMilliseconds;
  }

  public setModalShown(): void {
    const currentDate = new Date().toISOString();
    localStorage.setItem(this.storageKey, currentDate);
  }
}
