import { Component } from '@angular/core';

@Component({
  selector: 'app-over-auto-net',
  templateUrl: './over-auto-net.component.html',
  styleUrl: './over-auto-net.component.scss'
})
export class OverAutoNetComponent {

}
