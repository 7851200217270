import { Injectable } from "@angular/core";
import { Data as Advertisements } from "../models/advertisements.model";

@Injectable()
export class FavoritesService {

    constructor() { }

    public save(advertisement: Advertisements) {
        // Retrieve saved advertisements from local storage
        let savedAds: Advertisements[] = JSON.parse(localStorage.getItem('saved')!) || [];

        // Check if the advertisement is already saved
        const existingIndex = savedAds.findIndex(ad => ad.id === advertisement.id);

        if (existingIndex === -1) {
            // If the advertisement is not already saved, add it to the list
            savedAds.push(advertisement);

            // Save the updated list back to local storage
            localStorage.setItem('saved', JSON.stringify(savedAds));
        }
    }

    public remove(advertisementId: string) {
        // Retrieve saved advertisements from local storage
        let savedAds: Advertisements[] = JSON.parse(localStorage.getItem('saved')!) || [];

        // Find index of the advertisement to remove
        const indexToRemove = savedAds.findIndex(ad => ad.id === Number(advertisementId));

        if (indexToRemove !== -1) {
            // Remove the advertisement from the saved list
            savedAds.splice(indexToRemove, 1);

            // Save the updated list back to local storage
            localStorage.setItem('saved', JSON.stringify(savedAds));
        }
    }

    public get(): Advertisements[] {
        // Retrieve saved advertisements from local storage
        return JSON.parse(localStorage.getItem('saved')!) || [];
    }

    public getById(advertisementId: string): Advertisements | undefined {
        // Retrieve saved advertisements from local storage
        const savedAds: Advertisements[] = JSON.parse(localStorage.getItem('saved')!) || [];

        // Find the advertisement with the given id
        return savedAds.find(ad => ad.id === Number(advertisementId));
    }

    public count(): number {
        // Retrieve saved advertisements from local storage
        const savedAds: Advertisements[] = JSON.parse(localStorage.getItem('saved')!) || [];

        // Return the count of saved advertisements
        return savedAds.length;
    }

    public isSaved(advertisementId: number): boolean {
        // Retrieve saved advertisements from local storage
        const savedAds: Advertisements[] = JSON.parse(localStorage.getItem('saved')!) || [];
    
        // Check if the advertisement with the given id is saved
        return savedAds.some(ad => ad.id === Number(advertisementId));
      }
}
