import { Component } from '@angular/core';
import { Data as Reviews } from 'src/app/models/reviews.model';
import { ApiService } from 'src/app/services/api.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrl: './reviews.component.scss'
})
export class ReviewsComponent {
  public reviews!: Reviews[];
  
  constructor(
    private apiService: ApiService,
    private metaService: MetaService
  ) { }

  ngOnInit() {
    this.getReviews();

    this.metaService.updateMetaTags({
      titleKey: 'reviews.seo.title',
      descriptionKey: 'reviews.seo.description',
      keywordsKey: 'reviews.seo.keywords',
      authorKey: 'reviews.seo.author',
      ogTitleKey: 'reviews.seo.ogTitle',
      ogDescriptionKey: 'reviews.seo.ogDescription',
      ogImageKey: 'reviews.seo.ogImage',
      ogUrlKey: 'reviews.seo.ogUrl'
    });
  }

  public getReviews() {
    this.apiService.getReviews(25, 1, { publicationDate: "DESC" }).subscribe((data) => {
      this.reviews = data.data;
    });
  }

  public getStars(rating: number): boolean[] {
    const stars = [];
    const maxStars = 5;
    const ratingOutOfFive = Math.round(rating / 2); // Convert 1-10 rating to 1-5

    for (let i = 0; i < maxStars; i++) {
      stars.push(i < ratingOutOfFive);
    }
    return stars;
  }

}
