<title>Auto-net - Privacyverklaring</title>

<!-- Page container-->
<div class="container pt-5">
    <div class="container rounded bg-white mt-5 mb-md-4">
        <div class="row py-md-1">
            <div class="col-md-9 main-content mt-2">
                <div class="twelve columns">
                    <h1>Garantiebepaling Auto-net.nl B.V.</h1>

                    <h2>Garantie</h2>
                    <p>
                        Bij Auto-Net kunt u garantie bijkopen voor € 250,-, dit is voor 3 maanden met een maximum
                        kilometers van 10.000 km. Hier zijn een paar dingen van uitgesloten: Slijtage delen, Airco
                        delen, after-market delen en olieverbruik. Hier zeggen wij altijd duidelijk bij dat een
                        koppeling een slijtage deel is. De garantie is altijd vanuit onze vestiging in Apeldoorn. U
                        dient de auto zelf hier af te leveren en dan zullen wij de auto repareren op een efficiënte en
                        economische wijze, dit wil zeggen dat wanneer er gebruikte onderdelen leverbaar zijn dit
                        gebruikt zal worden. Vervangend vervoer is hier niet bij in begrepen echter wanneer er een
                        leenauto beschikbaar is kunt u deze meenemen, dit dient wel telefonisch overlegd te worden bij
                        het maken van de afspraak.
                    </p>

                    <h2>Voetstoots</h2>
                    <p>
                        U koopt de auto voetstoots, dit wil zeggen dat er geen garantie of enig consumentenrecht
                        van toepassing is.
                    </p>

                    <h2>Coulance</h2>
                    <p>
                        Wanneer u de auto zonder garantie heeft gekocht heeft u wel recht op onze coulance regeling, dit
                        is 4 weken vanaf aankoop. Wat houd de coulance regeling in: wanneer er iets met de auto is dient
                        u contact op te nemen met Auto-net in Apeldoorn om een afspraak te maken en wij zullen dan
                        diagnose gaan stellen, wanneer het in de coulance regeling valt dient u het onderdeel te betalen
                        en monteert Auto-Net het onderdeel kosteloos, de uitsluitingen zoals genoemd wanneer u garantie
                        bijkoopt is ook uitgesloten in de coulance regeling. U dient een telefonische afspraak te maken
                        met Auto-Net voor het aanleveren van u auto bij onze vestiging in Apeldoorn. Vervangend vervoer
                        is hier niet bij inbegrepen echter wanneer er een leenauto beschikbaar is kunt u deze meenemen,
                        dit dient wel telefonisch overlegd te worden bij het maken van de afspraak.
                    </p>

                    <h2>Onderzoeksplicht</h2>
                    <p>Koper dient zelf aan zijn onderzoeksplicht te voldoen.</p>

                    <h2>Annuleren</h2>
                    <p>Het kopen van een auto valt niet onder kopen op afstand dus u heeft geen bedenktijd, bij
                        annulering word er 15% annuleringskosten berekend van de aangekochte auto.</p>
                </div>
            </div>
        </div>
    </div>
</div>