<title>Auto-net - {{ 'favorites.title' | translate }}</title>

<!-- Page container-->
<div class="container mt-5 mb-md-4 py-5">
    <div class="row py-md-1 bg-white">
        <div class="row">
            <h2 class="">{{ 'favorites.savedAds' | translate }}</h2>
            <app-advertisement-overview [carOfTheWeek]="false" [advertisements]="advertisements"></app-advertisement-overview>
        </div>
    </div>
</div>
