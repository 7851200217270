import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string, words: boolean = false): string {
    if (!value) return value;

    if (words) {
      // Capitalize the first letter of each word
      return value.replace(/\b\w/g, char => char.toUpperCase());
    } else {
      // Capitalize the first letter of the entire string
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
}
