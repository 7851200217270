<!-- Navbar-->
<header class="navbar navbar-expand-lg navbar-light bg-white fixed-top" data-scroll-header
    (window:scroll)="windowScroll()">
    <div class="container"><a class="navbar-brand me-3 me-xl-4" routerLink=""><img class="d-block"
                src="../../../assets/images/logo/auto-net-logo.png" width="216" alt="Finder"></a>
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
            (click)="toggleMobileMenu()"><span class="navbar-toggler-icon"></span></button>

        <div class="collapse navbar-collapse order-lg-2" id="navbarNav">
            <ul class="navbar-nav navbar-nav-scroll" style="max-height: 35rem;">
                @for(item of menuItems;track $index){
                <li class="nav-item dropdown">
                    <a class="nav-link" (click)="onMenuClick(item.link)" href="javascript: void(0);"
                        id="topnav-components" role="button">
                        {{ 'header.' + item.label | translate}}
                    </a>
                </li>
                }
            </ul>
        </div>
    </div>
</header>

