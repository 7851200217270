import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import imageCompression from 'browser-image-compression';
import { ToastrService } from 'ngx-toastr';
import { Data as Advertisement } from 'src/app/models/advertisement.model';
import { Data as TradeRequest } from 'src/app/models/tradeRequest.model';
import { TradeRequestBody } from 'src/app/models/tradeRequestBoday.model';
import { ApiService } from 'src/app/services/api.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-auto-verkopen',
  templateUrl: './auto-verkopen.component.html',
  styleUrls: ['./auto-verkopen.component.scss']
})
export class AutoVerkopenComponent implements OnInit {
  public advertisement!: Advertisement;
  public request!: TradeRequest;
  public currentStep: 'general' | 'photos' | 'finish' = 'general';
  images: { file: File, url: string, loading: boolean }[] = [];
  uploadedImages: any[] = [];

  public requestForm = new FormGroup({
    salutationType: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(12)]),
    licensePlate: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(8)]),
    odometer: new FormControl('', [Validators.required, Validators.min(0)]),
    odometerUnit: new FormControl('km', Validators.required),
    comments: new FormControl('', null),
    advertisementId: new FormControl('', null),
    subscribeNewsletter: new FormControl(false, null),
    transmissionType: new FormControl('', Validators.required),
  });

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private metaService: MetaService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.metaService.updateMetaTags({
      titleKey: 'tradeInCar.seo.title',
      descriptionKey: 'tradeInCar.seo.description',
      keywordsKey: 'tradeInCar.seo.keywords',
      authorKey: 'tradeInCar.seo.author',
      ogTitleKey: 'tradeInCar.seo.ogTitle',
      ogDescriptionKey: 'tradeInCar.seo.ogDescription',
      ogImageKey: 'tradeInCar.seo.ogImage',
      ogUrlKey: 'tradeInCar.seo.ogUrl'
    });

    // Check route params
    this.activatedRoute.paramMap.subscribe((params) => {
      const requestId = params.get('requestId');
      const advertisementId = params.get('advertisementId');
      const token = params.get('token');

      if (advertisementId) {
        this.apiService.getAdvertisement(Number(advertisementId)).subscribe((response) => {
          this.advertisement = response.data;
        }, error => {
          if (error.error.errors[0] == 'Data not available anymore') {
            this.toastr.error(
              this.translate.instant('advertisement-detail.unavailable'),
              this.translate.instant('advertisement-detail.error'));
          } else {
            this.toastr.error(
              this.translate.instant('advertisement-detail.somethingWentWrong'),
              this.translate.instant('advertisement-detail.error')
            );
          }
        });
      }

      if (requestId && token) {
        // Validate trade request
        this.apiService.receivedTradeRequest(Number(requestId), token).subscribe((response) => {
          if (response.success) {
            this.toastr.success('De aanvraag is gevalideerd', 'Succes');
          } else {
            this.toastr.error('De aanvraag is niet gevalideerd', 'Fout');
          }
          // Return home
          this.router.navigate(['/']);
        }, error => {
          this.toastr.error('Er is iets mis gegaan bij het valideren van je aanvraag. Mogelijk is de link verlopen.', 'Fout');
          // Return home
          this.router.navigate(['/']);
        });
      }
    });

    // Check if there is a trade request in local storage
    if (localStorage.getItem('tradeRequest') !== null) {
      this.request = JSON.parse(localStorage.getItem('tradeRequest') ?? '{}');
      this.currentStep = 'photos';
    }
  }

  public triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  public submitRequestForm() {
    if (this.requestForm.invalid) {
      this.markFormGroupTouched(this.requestForm);
      return;
    }

    if (this.advertisement && this.advertisement.id) {
      // Set advertisementId if it's not set
      this.requestForm.patchValue({ advertisementId: String(this.advertisement.id) });
    }

    this.apiService.createTradeRequest(this.requestForm.value as TradeRequestBody).subscribe((response) => {
      if (response.success) {
        this.request = response.data;
        // Initialize photoIndex
        this.request.photoIndex = 0;
        localStorage.setItem('tradeRequest', JSON.stringify(this.request));
        this.toastr.success('Uw gegevens zijn succesvol opgeslagen.', 'Succes');
        this.currentStep = 'photos';
      } else {
        this.toastr.error('Er is iets misgegaan met het verzenden van uw aanvraag', 'Fout');
      }
      window.scrollTo(0, 0);
    }, error => {
      this.toastr.error('Er is iets misgegaan met het verzenden van uw aanvraag', 'Fout');
      window.scrollTo(0, 0);
    });
  }

  public finishTradeRequest() {
    this.apiService.finishTradeRequest(this.request.id, this.request.token).subscribe((response) => {
      if (response.success) {
        this.toastr.success('Uw aanvraag is succesvol verzonden.', 'Succes');
        localStorage.removeItem('tradeRequest');
        this.currentStep = 'finish';
      } else {
        this.toastr.error('Er is iets misgegaan met het verzenden van uw aanvraag', 'Fout');
      }
      window.scrollTo(0, 0);
    }, error => {
      this.toastr.error('Er is iets misgegaan met het verzenden van uw aanvraag', 'Fout');
      window.scrollTo(0, 0);
    });
  }
  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      files.slice(0, 12).forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = { file, url: e.target.result, loading: true };
          this.images.push(image);
          this.uploadImage(image).then(() => {
            // Reset file input
            event.target.value = '';
          }).catch(() => {
            // Reset file input
            event.target.value = '';
          });
        };
        reader.readAsDataURL(file);
      });
    }
  }

  public confirmDelete(index: number): void {
    const confirmation = confirm('Weet je zeker dat je deze foto wilt verwijderen?');
    if (confirmation) {
      this.removeImage(index);
    }
  }

  private removeImage(index: number): void {
    // Find index based on uploadedImages
    const imageId = this.uploadedImages[index].id;
    this.apiService.deleteImageFromRequest(this.request.id, this.request.token, imageId).subscribe((response) => {
      if (response.success) {
        this.uploadedImages.splice(index, 1);
        this.images.splice(index, 1);

        const request = JSON.parse(localStorage.getItem('tradeRequest') ?? '{ photoIndex: 1 }');
        const storageIndex = request.photoIndex;
        // Add to photoIndex
        this.request.photoIndex = storageIndex - 1;
        // Update local storage
        localStorage.setItem('tradeRequest', JSON.stringify(this.request));
      } else {
        this.toastr.error('Er is iets misgegaan met het verwijderen van de foto', 'Fout');
      }
    }, error => {
      this.toastr.error('Er is iets misgegaan met het verwijderen van de foto', 'Fout');
    });
  }

  public cancelTradeRequest() {
    localStorage.removeItem('tradeRequest');
    this.currentStep = 'general';
    window.scrollTo(0, 0);
  }

  private uploadImage(image: { file: File, url: string, loading: boolean }): Promise<any> {
    console.log(this.request);
    return new Promise((resolve, reject) => {
      // Compress the image
      const options = {
        maxSizeMB: 2, // Set the maximum size for the compressed image (2 MB in this example)
        maxWidthOrHeight: 1024, // Set the maximum width or height of the compressed image (1024px in this example)
        useWebWorker: false, // Use web worker for faster compression
      };

      imageCompression(image.file, options)
        .then((compressedFile) => {
          // Create a FormData object
          const formData = new FormData();

          const request = JSON.parse(localStorage.getItem('tradeRequest') ?? '{ photoIndex: 1 }');
          const index = request.photoIndex;

          // Append the compressed image file
          formData.append('image', compressedFile, compressedFile.name);
          formData.append('sequence', String(index));

          // Call the API service to upload the image
          this.apiService.addImageToRequest(this.request.id, this.request.token, formData).subscribe(
            (response) => {
              // Handle response
              if (response.success) {
                image.loading = false;
                this.uploadedImages.push({ id: response.data.id });
                // Add to photoIndex
                this.request.photoIndex = index + 1;
                // Update local storage
                localStorage.setItem('tradeRequest', JSON.stringify(this.request));

                resolve(response); // Resolve on success
              } else {
                this.images.splice(index - 1, 1);
                this.toastr.error('Er is iets misgegaan met het uploaden van foto ' + index, 'Fout');
                reject('Failed to upload image'); // Reject if there's an error in the response
              }
            },
            (error) => {
              image.loading = false;
              this.images.splice(index - 1, 1);
              this.toastr.error('Er is iets misgegaan met het uploaden van foto ' + index, 'Fout');
              reject(error); // Reject if there's an API error
            }
          );
        })
        .catch((error) => {
          console.error('Error compressing the image:', error);
          image.loading = false;
          this.toastr.error('Er is iets misgegaan met het comprimeren van de foto', 'Fout');
          reject(error); // Reject if image compression fails
        });
    });
  }
}
