import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { Data as Advertisement } from 'src/app/models/advertisement.model';
import { Data as Advertisements } from 'src/app/models/advertisements.model';
import { Paginator } from 'src/app/models/paginator.model';
import { Data as SearchOptions } from 'src/app/models/searchOptions.model';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-advertisement-list',
  templateUrl: './advertisement-list.component.html',
  styleUrls: ['./advertisement-list.component.scss']
})
export class AdvertisementListComponent implements OnInit {
  public advertisements: Advertisements[] = [];
  public lastViewedAdvertisement: Advertisement | null = null;
  public advertisementPaginator!: Paginator;

  public searchOptions!: SearchOptions;
  public filteredSearchOptions!: SearchOptions;

  public viewType: string = 'list';
  public isSearchFormReady: boolean = false;
  public searchForm!: FormGroup;

  private filter: any = null;
  private orderBy: any = { "dateDelivered": "DESC" };
  public customFilter: any = [];
  public currentPage: number = 1;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public favoritesService: FavoritesService,
    private toastr: ToastrService,
    private metaService: MetaService
  ) { }

  ngOnInit(): void {
    this.generateInitialFilter();
    this.lastViewedAdvertisement = localStorage.getItem('lastViewedAdvertisement') ? JSON.parse(localStorage.getItem('lastViewedAdvertisement')!) : null;

    this.metaService.updateMetaTags({
      titleKey: 'advertisement-list.seo.title',
      descriptionKey: 'advertisement-list.seo.description',
      keywordsKey: 'advertisement-list.seo.keywords',
      authorKey: 'advertisement-list.seo.author',
      ogTitleKey: 'advertisement-list.seo.ogTitle',
      ogDescriptionKey: 'advertisement-list.seo.ogDescription',
      ogImageKey: 'advertisement-list.seo.ogImage',
      ogUrlKey: 'advertisement-list.seo.ogUrl'
    });
  }

  private generateInitialFilter() {
    this.route.params.subscribe(params => {
      if (params['vehicletype'] === 'auto') {
        this.filter = ['cars'];
      }

      if (params['make']) {
        this.createFilter('Vehicle.makeId', [params['make']], 'contains');
      }
      if (params['model']) {
        this.createFilter('Vehicle.modelId', [params['model']], 'contains');
      }

      if (params['make'] || params['model']) {
        if (params['make'] && params['model']) {
          this.getSearchOptions([params['make']], [params['model']]);
        } else if (params['make']) {
          this.getSearchOptions([params['make']]);
        } else if (params['model']) {
          this.getSearchOptions([params['model']]);
        }
      } else {
        const lastView = localStorage.getItem('lastView');
        if (lastView) {
          const lastViewData = JSON.parse(lastView);
          this.customFilter = lastViewData.customFilter;
          this.orderBy = lastViewData.order;
          this.currentPage = lastViewData.page;
          this.advertisementPaginator = lastViewData.paginator;
          this.filter = lastViewData.filter;
        }

        const stockVehicles = lastView && this.filter && this.filter.includes('unsold');

        const extractFilterIds = (key: string) => {
          const filter = this.customFilter.find((filter: string[]) => filter[0] === key);
          return filter ? filter[2] : [];
        };

        const makeIds = extractFilterIds('Vehicle.makeId');
        const modelIds = extractFilterIds('Vehicle.modelId');
        const transmissionIds = extractFilterIds('Vehicle.transmissionTypeId');
        const bodyTypeIds = extractFilterIds('Vehicle.bodyTypeId');
        const fuelTypeIds = extractFilterIds('Vehicle.fuelTypeId');

        const priceFilters = this.customFilter.filter((filter: string[]) => filter[0] === 'Vehicle.consumerPrice');
        let minFilter = null;
        let maxFilter = null;
        priceFilters.forEach((filter: string[]) => {
          if (filter[1] === 'gte') {
            minFilter = filter[2];
          }
          if (filter[1] === 'lte') {
            maxFilter = filter[2];
          }
        });
        this.getSearchOptions(makeIds, modelIds, transmissionIds, bodyTypeIds, fuelTypeIds, stockVehicles, minFilter, maxFilter);
      }

      this.getAdvertisements(10, this.currentPage, { "dateDelivered": "DESC" }, this.filter, this.customFilter);
    });
  }

  public getAdvertisements(limit: number = 10, page: number = 1, orderBy: any = { "dateDelivered": "DESC" }, filter: any = this.filter, customFilter: any = this.customFilter) {
    this.apiService.getAdvertisements(limit, page, orderBy, filter, customFilter).subscribe((data) => {
      this.advertisements = data.data;
      this.advertisementPaginator = data.paginator;
      this.saveCurrentView();
    });
  }

  public onCheckboxChange(event: any, group: string, controlId: number, label: string) {
    const selectedCheckboxes = Object.keys(this.searchForm.get(group)?.value).filter(key => this.searchForm.get(`${group}.${key}`)?.value === true);

    if (group === 'makes' || group === 'models') {
      if (selectedCheckboxes.length === 0) {
        this.filteredSearchOptions.makes = this.searchOptions.makes;
        this.filteredSearchOptions.models = this.searchOptions.models;
        this.removeFilter(label);
        this.getAdvertisements(10, 1, { "dateDelivered": "DESC" }, this.filter, this.customFilter);
        return;
      }

      if (group === 'makes') {
        const makeIds = selectedCheckboxes.map(Number);
        this.filterModelsByMakes(makeIds);

        // Get all models for selected makes
        const allModelIds = this.searchOptions.models
          .filter(model => makeIds.includes(model.makeId))
          .map(model => model.id);

        // Select all models for selected makes
        this.setSelectedOptions('models', allModelIds);

        // Create filter for selected models
        this.createFilter('Vehicle.modelId', allModelIds, 'contains');

        // Set the makes filter
        this.createFilter('Vehicle.makeId', makeIds, 'contains');
      } else if (group === 'models') {
        const deselectedModelIds = this.searchOptions.models
          .filter(model => !selectedCheckboxes.includes(model.id.toString()))
          .map(model => model.id);

        // Identify makes to be deselected if all models for those makes are deselected
        const makeIdsToRemove = this.searchOptions.makes.filter(make => {
          const makeModelIds = this.searchOptions.models
            .filter(model => model.makeId === make.id)
            .map(model => model.id);
          const remainingModelIds = makeModelIds.filter(id => selectedCheckboxes.includes(id.toString()));
          return remainingModelIds.length === 0;
        }).map(make => make.id);

        // Deselect the makes that have all models deselected
        const remainingMakeIds = this.searchOptions.makes
          .filter(make => !makeIdsToRemove.includes(make.id))
          .filter(make => selectedCheckboxes.some(modelId => this.searchOptions.models.find(model => model.id === parseInt(modelId) && model.makeId === make.id)))
          .map(make => make.id);

        this.setSelectedOptions('makes', remainingMakeIds);
        this.createFilter('Vehicle.makeId', remainingMakeIds, 'contains');

        // Update models filter
        this.setSelectedOptions('models', selectedCheckboxes.map(Number));
        this.createFilter('Vehicle.modelId', selectedCheckboxes.map(Number), 'contains');
      }
    } else {
      if (selectedCheckboxes.length === 0) {
        this.removeFilter(label);
      } else {
        this.createFilter(label, selectedCheckboxes, 'contains');
      }
    }

    this.getAdvertisements(10, 1, { "dateDelivered": "DESC" }, this.filter, this.customFilter);
  }

  private filterModelsByMakes(makeIds: number[]): void {
    this.filteredSearchOptions.models = this.searchOptions.models.filter((model) => makeIds.includes(model.makeId));
  }

  private createFilter(label: string, values: any, operator: string): void {
    if (values === null || values === undefined || values.length === 0) {
      // Remove filter if values are null, undefined or empty
      this.customFilter = this.customFilter.filter((filter: string[]) => filter[0] !== label);
      return;
    }

    if (!this.customFilter) {
      this.customFilter = [];
    }

    if (label === 'Vehicle.consumerPrice') {
      let replaced = false;
      this.customFilter = this.customFilter.map((filter: string[]) => {
        if (filter[0] === label && filter[1] === operator) {
          replaced = true;
          return [label, operator, values];
        }
        return filter;
      });

      if (!replaced) {
        this.customFilter.push([label, operator, values]);
      }
    } else {
      // Remove the label if it exists
      this.customFilter = this.customFilter.filter((filter: string[]) => filter[0] !== label);
      // Add new filter with label included
      this.customFilter.push([label, operator, values]);
    }
  }


  private removeFilter(label: string): void {
    const filterIndex = this.customFilter.findIndex((filter: string[]) => filter[0] === label);

    if (filterIndex !== -1) {
      this.customFilter.splice(filterIndex, 1);
    }
  }

  public getSearchOptions(makeIds?: number[], modelIds?: number[], transmissionIds?: number[], bodyTypeIds?: number[], fuelTypeIds?: number[], stockVehicles?: boolean, minPrice?: number | null, maxPrice?: number | null) {
    this.apiService.getSearchOptions(this.filter).subscribe((data) => {
      this.searchOptions = data.data;
      this.filteredSearchOptions = { ...this.searchOptions };

      this.searchForm = this.formBuilder.group({
        makes: this.initializeFormGroup(this.searchOptions.makes),
        models: this.initializeFormGroup(this.searchOptions.models),
        transmissions: this.initializeFormGroup(this.searchOptions.transmissionTypes),
        bodyTypes: this.initializeFormGroup(this.searchOptions.bodyTypes),
        fuelTypes: this.initializeFormGroup(this.searchOptions.fuelTypes),
        maxPrice: this.formBuilder.control(this.searchOptions.consumerPrice.max, [
          Validators.required,
          Validators.min(this.searchOptions.consumerPrice.min),
          Validators.max(this.searchOptions.consumerPrice.max)
        ]),
        minPrice: this.formBuilder.control(this.searchOptions.consumerPrice.min, [
          Validators.required,
          Validators.min(this.searchOptions.consumerPrice.min),
          Validators.max(this.searchOptions.consumerPrice.max)
        ]),
        inStock: this.formBuilder.control(stockVehicles)
      });

      this.addDebouncedPriceChangeEvent('minPrice');
      this.addDebouncedPriceChangeEvent('maxPrice');

      this.setSelectedOptions('makes', makeIds);
      this.setSelectedOptions('models', modelIds);
      this.setSelectedOptions('transmissions', transmissionIds);
      this.setSelectedOptions('bodyTypes', bodyTypeIds);
      this.setSelectedOptions('fuelTypes', fuelTypeIds);

      if (minPrice) {
        this.searchForm.get('minPrice')?.setValue(minPrice);
      }
      if (maxPrice) {
        this.searchForm.get('maxPrice')?.setValue(maxPrice);
      }

      this.isSearchFormReady = true;
    });
  }

  private initializeFormGroup(options: any[]): FormGroup {
    const formGroup: { [key: string]: any } = {};
    options.forEach(option => {
      formGroup[option.id] = false;
    });
    return this.formBuilder.group(formGroup);
  }

  private setSelectedOptions(group: string, ids?: number[]): void {
    if (ids) {
      ids.forEach(id => {
        this.searchForm.get(group)?.get(id.toString())?.setValue(true);
      });
    }
  }

  public filterInStockChange($event: any) {
    if ($event.target.checked) {
      if (!this.filter || !this.filter.includes('unsold')) {
        this.filter.push('unsold');
      }
    } else {
      this.filter = this.filter?.filter((filter: string) => filter !== 'unsold');
    }
    this.getAdvertisements(10, 1, { "dateDelivered": "DESC" }, this.filter, this.customFilter);
  }

  public addDebouncedPriceChangeEvent(controlName: string) {
    const control = this.searchForm.get(controlName);
    if (control) {
      control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(() => {
        this.createFilter('Vehicle.consumerPrice', this.searchForm.controls['minPrice'].value, 'gte');
        this.createFilter('Vehicle.consumerPrice', this.searchForm.controls['maxPrice'].value, 'lte');
        this.getAdvertisements(10, this.currentPage, { "dateDelivered": "DESC" }, this.filter, this.customFilter);
      });
    }
  }

  public sortChange(event: any) {
    let sortBy = '';
    switch (event.target.value) {
      case 'Aprice':
      case 'Dprice':
        sortBy = 'Vehicle.consumerPrice';
        break;
      case 'Ayear':
      case 'Dyear':
        sortBy = 'Vehicle.buildYear';
        break;
      case 'Amileage':
      case 'Dmileage':
        sortBy = 'Vehicle.odometer';
        break;
    }

    const sortOrder = event.target.value.startsWith('D') ? 'DESC' : 'ASC';
    this.orderBy = { [sortBy]: sortOrder };
    this.getAdvertisements(10, 1, this.orderBy, this.filter, this.customFilter);
  }

  public addToFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.save(advertisement);
    this.toastr.success('Deze advertentie is toegevoegd in je favorieten', 'Toegevoegd aan favorieten');
  }

  public removeFromFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.remove(advertisement.id.toString());
    this.toastr.warning('Deze advertentie is verwijderd uit je favorieten', 'Verwijderd uit favorieten');
  }

  public nextPage() {
    this.currentPage++;
    this.getAdvertisements(10, this.currentPage, this.orderBy, this.filter, this.customFilter);
    // Move up
    window.scrollTo(0, 0);
  }

  public navigateToPage(page: number) {
    this.currentPage = page;
    this.getAdvertisements(10, page, this.orderBy, this.filter, this.customFilter);
    // Move up
    window.scrollTo(0, 0);
  }

  public getPaginationArray(): number[] {
    return Array.from({ length: this.advertisementPaginator.totalPages }, (_, i) => i + 1);
  }

  public removeAllFilters() {
    this.customFilter = [];
    localStorage.removeItem('lastView');

    // Check if url contains make and model, and reset
    this.route.params.subscribe(params => {
      if (params['make'] || params['model']) {
        this.router.navigate(['/occasions/auto']);
      }
    });

    this.searchForm.reset(false, { emitEvent: false });
    this.generateInitialFilter();
  }

  public sidebarHide() {
    document.getElementById('filters-sidebar')?.classList.remove('show');
    document.querySelector('.vertical-overlay')?.classList.remove('show');
  }

  public filterSidebar() {
    document.getElementById('filters-sidebar')?.classList.toggle('show');
    document.querySelector('.vertical-overlay')?.classList.toggle('show');
  }

  private saveCurrentView() {
    const advertisementIds = this.advertisements.map(advertisement => advertisement.id);
    localStorage.setItem('lastView', JSON.stringify({
      path: this.router.url,
      queryParams: this.route.snapshot.queryParams,
      paginator: this.advertisementPaginator,
      page: this.currentPage,
      filter: this.filter,
      order: this.orderBy,
      customFilter: this.customFilter,
      advertisementIds: advertisementIds
    }));
  }

  public navigateToAdvertisement(id: number) {
    this.saveCurrentView();
    this.router.navigate([`occasion/${id}`]);
  }
}
