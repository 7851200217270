import { Component } from '@angular/core';

@Component({
  selector: 'app-privacyverklaring',
  templateUrl: './privacyverklaring.component.html',
  styleUrl: './privacyverklaring.component.scss'
})
export class PrivacyverklaringComponent {

}
