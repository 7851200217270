import { Component, OnInit } from '@angular/core';
import { Data as Advertisements } from 'src/app/models/advertisements.model';
import { FavoritesService } from 'src/app/services/favorites.service';

@Component({
  selector: 'app-favorieten',
  templateUrl: './favorieten.component.html',
  styleUrls: ['./favorieten.component.scss'] // Use 'styleUrls' instead of 'styleUrl'
})
export class FavorietenComponent implements OnInit {
  public advertisements: Advertisements[] = [];
  
  constructor(private favoritesService: FavoritesService) { }

  ngOnInit(): void {
    // Get saved advertisements when the component initializes
    this.advertisements = this.favoritesService.get();
  }
}
