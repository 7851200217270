import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Data as Advertisements } from 'src/app/models/advertisements.model';
import { FavoritesService } from 'src/app/services/favorites.service';

@Component({
  selector: 'app-advertisement-overview',
  templateUrl: './advertisement-overview.component.html',
  styleUrls: ['./advertisement-overview.component.scss']
})
export class AdvertisementOverviewComponent {
  @Input() advertisements: Advertisements[] = [];
  @Input() carOfTheWeek: boolean = false;

  constructor(
    public favoritesService: FavoritesService,
    private toastr: ToastrService
  ) {
  }

  public config = {
    initialSlide: 0,
    slidesPerView: 1,
    navigation: true,
    loop: true
  };

  public addToFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.save(advertisement);
    this.toastr.success('Deze advertentie is toegevoegd in je favorieten', 'Toegevoegd aan favorieten');
  }

  public removeFromFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.remove(advertisement.id.toString());
    this.toastr.warning('Deze advertentie is verwijderd uit je favorieten', 'Verwijderd uit favorieten');
  }
}
