import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ScrollService } from './services/scroll.service';
import { ModalStateService } from './services/modal-state.service';
import { NieuwsbriefModalComponent } from './pages/nieuwsbrief-modal/nieuwsbrief-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private statusChangeSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  public showDevPassword = false;
  devPassword: string = '';

  constructor(
    private ccService: NgcCookieConsentService,
    private translate: TranslateService,
    private scrollService: ScrollService,
    private modalStateService: ModalStateService,
    private modalService: NgbModal
  ) {
    if (environment.production == false) {
      this.showDevPassword = localStorage.getItem('showDevPassword') !== 'false';
    } else {
      this.showDevPassword = false;
    }

    // Get the language code of the user's browser
    const userLanguage = navigator.language.split('-')[0]; // Extract the language code, e.g., 'en' from 'en-US'

    // Check if the user's language is supported, otherwise fallback to a default language
    const supportedLanguages = ['nl', 'en']; // Add more languages as needed
    const defaultLanguage = 'nl'; // Default language if user's language is not supported

    // Get the selected language from local storage or use the default language
    let selectedLanguage = localStorage.getItem('selectedLanguage');
    if (!selectedLanguage || !supportedLanguages.includes(selectedLanguage)) {
      selectedLanguage = userLanguage;
      if (!supportedLanguages.includes(selectedLanguage)) {
        selectedLanguage = defaultLanguage;
      }
      localStorage.setItem('selectedLanguage', selectedLanguage);
    }

    // Set the default language and use the selected language
    this.translate.setDefaultLang(selectedLanguage);
    this.translate.use(selectedLanguage);

    // Update local storage when the language changes
    this.translate.onLangChange.subscribe((event) => {
      const currentLanguage = localStorage.getItem('selectedLanguage');
      if (event.lang !== currentLanguage) {
        localStorage.setItem('selectedLanguage', event.lang);
        // Reload page only once
        if (localStorage.getItem('langChange') == null) {
          localStorage.setItem('langChange', 'true');
          window.location.reload();
        } else {
          localStorage.removeItem('langChange');
          window.location.reload();
        }
      }
    });
  }

  ngOnInit() {
    if (this.modalStateService.shouldShowModal()) {
      setTimeout(() => {
        NieuwsbriefModalComponent.open(this.modalService);
      }, 3000);
    }

    if (!this.ccService.hasAnswered() || !this.ccService.hasConsented()) {
      // Subscribe to status change events
      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // Handle status change events if necessary
        });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // Handle no cookie law events if necessary
        });
    }
  }

  public submitDevPassword() {
    if (this.devPassword === 'dev') {
      this.showDevPassword = false;
      localStorage.setItem('showDevPassword', 'false');
    }
  }

  ngOnDestroy() {
    this.statusChangeSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();
  }
}
