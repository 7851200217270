import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private meta: Meta,
    private titleService: Title,
    private translate: TranslateService
  ) { }

  updateMetaTags(keys: {
    titleKey?: string,
    descriptionKey?: string,
    keywordsKey?: string,
    authorKey?: string,
    ogTitleKey?: string,
    ogDescriptionKey?: string,
    ogImageKey?: string,
    ogUrlKey?: string
  }, params?: any) {
    if (keys.titleKey) {
      this.translate.get(keys.titleKey, params).subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
    }
    if (keys.descriptionKey) {
      this.translate.get(keys.descriptionKey, params).subscribe((res: string) => {
        this.meta.updateTag({ name: 'description', content: res });
      });
    }
    if (keys.keywordsKey) {
      this.translate.get(keys.keywordsKey, params).subscribe((res: string) => {
        this.meta.updateTag({ name: 'keywords', content: res });
      });
    }
    if (keys.authorKey) {
      this.translate.get(keys.authorKey, params).subscribe((res: string) => {
        this.meta.updateTag({ name: 'author', content: res });
      });
    }
    if (keys.ogTitleKey) {
      this.translate.get(keys.ogTitleKey, params).subscribe((res: string) => {
        this.meta.updateTag({ property: 'og:title', content: res });
      });
    }
    if (keys.ogDescriptionKey) {
      this.translate.get(keys.ogDescriptionKey, params).subscribe((res: string) => {
        this.meta.updateTag({ property: 'og:description', content: res });
      });
    }
    if (keys.ogImageKey) {
      this.translate.get(keys.ogImageKey, params).subscribe((res: string) => {
        this.meta.updateTag({ property: 'og:image', content: res });
      });
    }
    if (keys.ogUrlKey) {
      this.translate.get(keys.ogUrlKey, params).subscribe((res: string) => {
        this.meta.updateTag({ property: 'og:url', content: res });
      });
    }
  }
}
