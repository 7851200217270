import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Data as Advertisements } from 'src/app/models/advertisements.model';
import { Data as Counters } from 'src/app/models/counters.model';
import { Data as Reviews } from 'src/app/models/reviews.model';
import { Data as SearchOptions, Model } from 'src/app/models/searchOptions.model';
import { ApiService } from 'src/app/services/api.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { MetaService } from 'src/app/services/meta.service';

type ContentTypes = 'carOfTheWeek' | 'lowPrice' | 'new' | 'sold';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit {
  public advertisements: Advertisements[] = [];
  public searchOptions!: SearchOptions;
  public filteredModels: Model[] = [];
  public counters!: Counters;
  public reviews!: Reviews[];

  // Pill selection
  public selectedContent: ContentTypes = 'carOfTheWeek';

  public searchForm = new FormGroup({
    make: new FormControl({ value: null, disabled: false }, Validators.required),
    model: new FormControl({ value: null, disabled: true }),
  });

  constructor(
    private apiService: ApiService,
    private router: Router,
    private favoritesService: FavoritesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private metaService: MetaService
  ) {
    this.showContent('carOfTheWeek');
    this.getSearchOptions();
    this.getCounters();
    this.getReviews();
  }

  ngOnInit(): void {
    this.metaService.updateMetaTags({
      titleKey: 'home.seo.title',
      descriptionKey: 'home.seo.description',
      keywordsKey: 'home.seo.keywords',
      authorKey: 'home.seao.author',
      ogTitleKey: 'home.seo.ogTitle',
      ogDescriptionKey: 'home.seo.ogDescription',
      ogImageKey: 'home.seo.ogImage',
      ogUrlKey: 'home.seo.ogUrl',
    });

    // Check if we are on the email verification route
    this.route.params.subscribe(params => {
      if (params['action'] === 'verify') {
        this.apiService.verifyNewsletterSubscription(params['email'], params['hash']).subscribe((data) => {
          if (data.success === true) {
            this.toastr.success('Je email is geverifieerd, je bent nu ingeschreven voor de nieuwsbrief', 'Email geverifieerd');
          } else {
            this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.', 'Fout bij verifiëren email');
          }
          this.router.navigate(['/']);
        }, error => {
          this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.', 'Fout bij verifiëren email');
          this.router.navigate(['/']);
        });
      }

      if (params['action'] === 'unsubscribe') {
        this.apiService.unsubscribeNewsletterSubscription(params['email'], params['hash']).subscribe((data) => {
          if (data.success === true) {
            this.toastr.success('Je bent uitgeschreven voor de nieuwsbrief', 'Uitgeschreven');
          } else {
            this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.', 'Fout bij uitschrijven nieuwsbrief');
          }
        }, error => {
          this.toastr.error('Er is iets fout gegaan, probeer het later opnieuw.', 'Fout bij verifiëren email');
          this.router.navigate(['/']);
        });
      }
    });
  }

  public generateSearchUrl() {
    let url = '/occasions';

    if (this.searchForm.controls.make.value) {
      url += `/merk/${this.searchForm.controls.make.value}`;
    }

    if (this.searchForm.controls.model.value) {
      url += `/model/${this.searchForm.controls.model.value}`;
    }

    this.router.navigate([url]);
  }

  public makeSelected() {
    this.searchForm.controls.model.enable();
    // Filter out models for the selected make
    this.filteredModels = this.filterModels(Number(this.searchForm.controls.make.value));
  }

  public filterModels(makeId: number) {
    return this.searchOptions.models.filter(model => model.makeId === makeId);
  }

  public getAdvertisements(limit: number = 10, page: number = 1, orderBy?: {} | null, filter?: any, customFilter?: [{}]) {
    this.apiService.getAdvertisements(limit, page, orderBy, filter, customFilter).subscribe((data) => {
      this.advertisements = data.data;
    });
  }

  public getReviews() {
    this.apiService.getReviews(5, 1, { publicationDate: "DESC" }).subscribe((data) => {
      this.reviews = data.data;
    });
  }

  public getStars(rating: number): boolean[] {
    const stars = [];
    const maxStars = 5;
    const ratingOutOfFive = Math.round(rating / 2); // Convert 1-10 rating to 1-5

    for (let i = 0; i < maxStars; i++) {
      stars.push(i < ratingOutOfFive);
    }
    return stars;
  }

  public getSearchOptions() {
    this.apiService.getSearchOptions().subscribe((data) => {
      this.searchOptions = data.data;
    });
  }

  public getCounters() {
    this.apiService.getCounters().subscribe((data) => {
      this.counters = data.data;
    });
  }

  public addToFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.save(advertisement);
    this.toastr.success('Deze advertentie is toegevoegd in je favorieten', 'Toegevoegd aan favorieten');
  }

  public removeFromFavorites(advertisement: Advertisements) {
    event?.stopPropagation();
    this.favoritesService.remove(advertisement.id.toString());
    this.toastr.warning('Deze advertentie is verwijderd uit je favorieten', 'Verwijderd uit favorieten');
  }

  public showContent(contentType: ContentTypes) {
    this.selectedContent = contentType;

    switch (contentType) {
      case 'carOfTheWeek':
        this.getAdvertisements(1, 1, null, ["spotlightVehicle"]);
        break;
      case 'new':
        this.getAdvertisements(99, 1, { "dateDelivered": "DESC" }, ["newArrivals"]);
        break;
      case 'sold':
        this.getAdvertisements(99, 1, { "dateDelivered": "DESC" }, ["sold"]);
        break;
    }
  }

  public navigateToAdvertisement(id: number) {
    this.router.navigate([`occasion/${id}`]);
  }
}
